import { Injectable } from "@angular/core";
import { City } from "../common/City";
import { LogRestApiService } from "./rest-api/log-rest-api.service";
import { moment } from "../common/Moment";
import { ILog } from "../common/ILog";
import { BrowserFingerprintService } from "./browser-fingerprint.service";
import { LogEvent } from "../common/LogEvent";
import { SupplyObject } from "../common/SupplyObject";
import { Trafo } from "../common/Trafo";
import { SupplyObjectService } from "./supply-object.service";
import LatLng = google.maps.LatLng;

@Injectable()
export class LogService {

  constructor(private logRestApi: LogRestApiService,
    private supplyObjectService: SupplyObjectService,
    private browserFingerprintService: BrowserFingerprintService) {
  }

  public async log(logEvent: LogEvent, supplyObject?: SupplyObject, geolocation?: LatLng): Promise<void> {
    this.browserFingerprintService.browserHash$.subscribe(hash => {
      let log: ILog = {
        dateTime: moment.now,
        fingerprint: hash,
        event: logEvent,
        coordinates: null,
        trafoNumber: null,
        zip: null,
        city: null,
        supplyState: null,
      };
      if (geolocation) {
        log.coordinates = geolocation.toString();
      }
      if (supplyObject) {
        log.supplyState = supplyObject.supplyState;
        if (supplyObject instanceof City) {
          log.zip = (supplyObject as City).getZip();
          log.city = (supplyObject as City).getCity();
        } else if (supplyObject instanceof Trafo) {
          log.trafoNumber = (supplyObject as Trafo).id;
        }
      }
      this.logApi(log);
    });
  }

  public getSupplyObject(id: number): SupplyObject {
    let supplyObject: SupplyObject = null;
    if (id) {
      supplyObject = this.supplyObjectService.getSupplyObjectById(id);
    }
    return supplyObject;
  }

  public tel(supplyObject: SupplyObject): void {
    this.log(LogEvent.StoerungsmeldungTelefon, supplyObject, null);
  }

  private logApi(log): void {
    this.logRestApi.log(log).subscribe();
  }

}
