<mat-form-field>
    <mat-label>{{ "LOCATOR.PLACEHOLDER_LAGOULE" | bfcTranslate }}</mat-label>
    <input #autocomplete type="text" matInput [formControl]="control" [matAutocomplete]="auto" />
    <bfe-icon name="bfc-interaction-icon-close" class="locator-close-icon" (click)="control.setValue(null)" *ngIf="control.value !== null"></bfe-icon>
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let city of filteredCities | async" [value]="city" (onSelectionChange)="onCitySelect(city)">
        {{ city ? city.getDisplayText() : translateService.translate("LOCATOR.NOT_IN_SUPPLY_AREA") | nvtReplaceCompanyName }}
    </mat-option>
</mat-autocomplete>
