<bfc-single-page-layout-full-width>
    <div fxLayout="row" fxLayout.xs="column" fxFlex="auto">

        <!-- content left -->
        <div fxLayout="column" fxFlex.gt-xs="40" fxFlex.xs="none" class="content-left">
            <h1 fxHide fxShow.gt-xs>{{ 'MAP_PAGE.TITLE' | bfcTranslate }}</h1>
            <bfe-notification *ngIf="globalNotification" id="global-notification" class="first-notification"
                              [notification]="globalNotification"></bfe-notification>

            <ng-container *ngIf="noBlackout">
                <bfe-notification *ngIf="blackoutNotification" id="blackout-notification" class="first-notification"
                                  [notification]="blackoutNotification"></bfe-notification>
            </ng-container>

            <app-google-locator *ngIf="!isLagoule" (locate)="locateLatLng($event)" (scrollMobile)="scrollMobile()" [ngClass.xs]="{'bfc-margin-top' : true}"
                                [disabled]="showLoadingIndicator"></app-google-locator>

            <app-locator *ngIf="isLagoule" [cities]="getCities(supplyObjectService.supplyObjects)" (locate)="locateSupplyObject($event)"
                         (scrollMobile)="scrollMobile()" [ngClass.xs]="{'bfc-margin-top' : true}" [disabled]="showLoadingIndicator"></app-locator>

            <a id="location" class="bfc-margin-top location-mobile-link" (click)="requestGeoLocation()"
               [style.visibility]="mapComponent?.map ? 'hidden' : 'visible'" fxHide [fxShow.xs]="isGeolocationSupported && !showLoadingIndicator">
                <bfe-icon name="bfc-interaction-icon-locate"
                          class="location-icon location-mobile primary-default-color bfc-margin-right"></bfe-icon>
            </a>

            <a class="content-link" (click)="requestGeoLocation()" *ngIf="isGeolocationSupported && !showLoadingIndicator">
                <bfe-icon name="bfc-interaction-icon-locate" class="style-icon bfe-icon-24"></bfe-icon>
                <span>{{ 'MAP_PAGE.USE_CURRENT_LOCATION' | bfcTranslate }}</span>
            </a>

            <a class="content-link" (click)="singlePageRouterService.state.next('hints')">
                <bfe-icon name="bfc-interaction-icon-big" class="style-icon bfe-icon-24"></bfe-icon>
                <span>{{ 'MAP_PAGE.HINTS_LINK' | bfcTranslate }}</span>
            </a>

            <a class="content-link" (click)="openContactLink()">
                <bfe-icon name="bfc-interaction-icon-big" class="style-icon bfe-icon-24"></bfe-icon>
                <span>{{ 'MAP_PAGE.CONTACT_LINK' | bfcTranslate }}</span>
            </a>

            <a class="content-link" (click)="singlePageRouterService.state.next('global-message')"
               *ngIf="showGlobalMessageLink">
                <bfe-icon name="bfc-icon-pencil" class="style-icon bfe-icon-24"></bfe-icon>
                <span>{{ 'MAP_PAGE.GLOBAL_MESSAGE_LINK' | bfcTranslate }}</span>
            </a>

            <mat-checkbox class="bfc-checkbox-primary content-link" (change)="setShowAllPolygons()"
                          *ngIf="hasAnyValidNVTRole">
                {{ 'MAP_PAGE.SHOW_POLYGONS' | bfcTranslate }}
            </mat-checkbox>

            <app-disclaimer fxShow.xs="false"></app-disclaimer>
        </div>
        <!-- content right -->
        <div fxLayout class="map-container">
            <bfc-loading-overlay [loading]="showLoadingIndicator" fxLayout="column" fxFlex="noshrink">
                <app-map #map fxLayout="column" fxFlex="noshrink"></app-map>
            </bfc-loading-overlay>
        </div>
    </div>
</bfc-single-page-layout-full-width>
