import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { ILog } from "../../common/ILog";
import { ThemeNameTransformService } from "../theme-name-transform.service";

@Injectable()
export class LogRestApiService {
  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    private realmNameTransformService: ThemeNameTransformService) {
  }

  public log(log: ILog): Observable<ILog> {
    const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
    return this.httpClient.post<ILog>(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/log", log, { params });
  }
}
