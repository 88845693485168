import { Injectable } from "@angular/core";
import { SelfServiceRestApiService } from "./rest-api/self-service-rest-api.service";
import { map } from "rxjs/operators";
import { Person } from "../common/model/person";
import { Observable } from "rxjs";

@Injectable()
export class SelfServiceService {

  constructor(private selfServiceRestApiService: SelfServiceRestApiService) {

  }

  public getPerson(): Observable<Person> {
    return this.selfServiceRestApiService.getPerson().pipe(
      map((person: Person) => {
        return person;
      }),
    );
  }

}
