import { Injectable } from "@angular/core";
import { MultiPolygon } from "../common/MultiPolygon";
import LatLng = google.maps.LatLng;

@Injectable()
export class PolygonService {

    constructor() {
    }

    /**
     * Checks if a given point is inside a multipolygon by checking each contained polygon.
     *
     * @param coordinates The coordinates.
     * @param multiPolygon The multipolygon.
     * @return True if coordinates are inside the multipolygon.
     */
    public pointIsInsideMultiPolygon(coordinates: LatLng, multiPolygon: MultiPolygon): boolean {
        // Check if point is inside one of multi polygon's polygon
        for (const polygon of multiPolygon.coordinates) {
            if (this.pointIsInsidePolygon(coordinates, polygon)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Checks if a given point is inside a polygon.
     * See https://stackoverflow.com/questions/217578/how-can-i-determine-whether-a-2d-point-is-within-a-polygon
     *
     * @param coordinates The coordinates.
     * @param polygon The polygon coordinates.
     * @return True if coordinates are inside the polygon.
     */
    public pointIsInsidePolygon(coordinates: LatLng, polygon: number[][][]): boolean {
        // Check if point is inside bounding rectangle of exterior ring (main polygon)
        let inside = false;
        if (polygon[0] != null) {
            inside = this.insideBoundingRectangle(coordinates, polygon[0]);
        }

        // If point is outside bounding rectangle, we are done!
        if (!inside) {
            return false;
        }

        // If point is inside bounding rectangle, check again with ray casting algorithm of exterior ring (main polygon)
        inside = false;
        inside = this.insideByRayCasting(coordinates, polygon[0]);

        // If point is outside by ray casting, we are done!
        if (!inside) {
            return false;
        }

        // If point is inside exterior ring (main polygon), check inner rings (excludes) with ray casting algorithm
        const holes = polygon.slice(1);  // remove first element (outer ring), and keep the rest (inner rings)
        for (const hole of holes) {
            if (this.insideByRayCasting(coordinates, hole)) {
                inside = false;
                break;
            }
        }
        // We are done!
        return inside;
    }

    /**
     * Checks if a given point is inside the bounding rectangle of a polygon.
     *
     * @param coordinates The coordinates.
     * @param path The polygon path.
     * @return True if coordinates are inside the bounding rectangle of a polygon.
     */
    public insideBoundingRectangle(coordinates: LatLng, path: number[][]): boolean {
        let lngMin = Number.MAX_VALUE;
        let lngMax = -Number.MAX_VALUE;
        let latMin = Number.MAX_VALUE;
        let latMax = -Number.MAX_VALUE;

        // Iterate over all points of the polygon and find the min/max values of x and y.
        for (const point of path) {
            lngMin = Math.min(point[0], lngMin);
            lngMax = Math.max(point[0], lngMax);
            latMin = Math.min(point[1], latMin);
            latMax = Math.max(point[1], latMax);
        }

        // Check if x and y of point is inside min/may of x and y.
        return !(coordinates.lng() < lngMin || coordinates.lng() > lngMax
            || coordinates.lat() < latMin || coordinates.lat() > latMax);
    }

    /**
     * Checks if a given point is inside a polygon.
     *
     * Draw a semi-infinite ray horizontally out from the test point, and count how many edges it crosses.
     * At each crossing, the ray switches between inside and outside. This is called the Jordan curve theorem.
     * It's tracking of whether the number of edges crossed are even or odd. If it's odd, it's inside.
     *
     * @param coordinates The coordinates.
     * @param path The polygon path.
     * @return True if coordinates are inside a polygon.
     */
    public insideByRayCasting(coordinates: LatLng, path: number[][]): boolean {
        let inside = false;
        let i, j;
        for (i = 0, j = path.length - 1; i < path.length; j = i++) {
            if (((path[i][1] > coordinates.lat()) != (path[j][1] > coordinates.lat()))
                && (coordinates.lng() <
                    (path[j][0] - path[i][0]) * (coordinates.lat() - path[i][1])
                        / (path[j][1] - path[i][1]) + path[i][0]))
                inside = !inside;
        }

        return inside;
    }

}
