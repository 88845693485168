import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class ThemeNameTransformService { 

  constructor(private bfcConfigurationService: BfcConfigurationService) {
  }

  public transformThemeNameToSupplier(): HttpParams {
    let supplierString;
    if (this.bfcConfigurationService.environment.theme === this.bfcConfigurationService.configuration.lagouleTheme) {
      supplierString = "lagoule";
    } else {
      supplierString = this.bfcConfigurationService.environment.theme;
    }
    return new HttpParams().set("supplier", supplierString);
  }

}
