import { Component, OnDestroy } from "@angular/core";
import { GlobalMessageService } from "../../services/global-message.service";
import { moment } from "../../common/Moment";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { SinglePageRouterService } from "../../services/single-page-router.service";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcTranslationService } from "@bfl/components/translation";
import { IGlobalMessageData } from "../../services/rest-api/global-message-rest-api.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";

@Component({
  selector: "app-global-message-page",
  templateUrl: "./global-message-page.component.html",
  styleUrls: ["./global-message-page.component.scss"],
})
export class GlobalMessagePageComponent implements OnDestroy {

  public dateFormat: string = "DD.MM.YYYY HH:mm";

  public now = new Date();

  public fromString: string;

  public untilString: string;

  public messageDe: string = "";

  public messageFr: string = "";

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private globalMessageService: GlobalMessageService,
    private bfcAuthenticationService: BfcAuthenticationService,
    private bfcConfigurationService: BfcConfigurationService,
    private bfcTranslationService: BfcTranslationService,
    public singlePageRouterService: SinglePageRouterService,
    public bfcNotificationService: BfcNotificationService,
  ) {
    this.initComponent();
  }

  private initComponent(): void {
    this.fromString = this.formatDate(new Date(
      this.now.getFullYear(), this.now.getMonth(), this.now.getDate(), this.now.getHours(), this.now.getMinutes(),
    ));
    this.untilString = this.fromString;

    const configTheme: string = this.bfcConfigurationService.environment.theme;
    const lagouleTheme: string = this.bfcConfigurationService.configuration.lagouleTheme;
    const hasNvtRole: boolean = this.bfcAuthenticationService.hasRealmRole(
      this.bfcConfigurationService.configuration.roleNvt);
    const hasNvtLagouleRole: boolean = this.bfcAuthenticationService.hasRealmRole(
      this.bfcConfigurationService.configuration.roleNvtLagoule);

    // (ROLE_NVT and not theme lagoule (theme aek, bkw or onyx)) or (ROLE_NVT_LAGOULE and theme lagoule)
    if ((hasNvtRole && configTheme != lagouleTheme) || (hasNvtLagouleRole && configTheme == lagouleTheme)) {
      this.globalMessageService.getGlobalMessage().pipe(takeUntil(this.unsubscribe)).subscribe(
        (globalMessageData: IGlobalMessageData) => {
          this.fromString = globalMessageData.validFrom !== null ? this.formatDate(
            new Date(globalMessageData.validFrom)) : "";
          this.untilString = globalMessageData.validTill !== null ? this.formatDate(
            new Date(globalMessageData.validTill)) : "";
          this.messageDe = globalMessageData.messageDe;
          this.messageFr = globalMessageData.messageFr;
        },
        () => {
          this.fromString = undefined;
          this.untilString = undefined;
          this.messageDe = "";
          this.messageFr = "";
        },
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public save(): void {
    let fromMoment: any = moment(this.fromString, this.dateFormat);
    let untilMoment: any = moment(this.untilString, this.dateFormat);

    if (!fromMoment.isValid() || !untilMoment.isValid() || fromMoment >= untilMoment) {
      this.bfcNotificationService.showNotification({
        type: BfcNotificationType.ERROR,
        message: this.bfcTranslationService.translate("GLOBAL_MESSAGE_PAGE.TIME_RANGE_INVALID"),
        options: {
          "duration": 3000,
        },
      });
    } else if (this.messageDe === undefined || this.messageFr === undefined) {
      this.bfcNotificationService.showNotification({
        type: BfcNotificationType.ERROR,
        message: this.bfcTranslationService.translate("GLOBAL_MESSAGE_PAGE.MESSAGE_TOO_LONG"),
        options: {
          "duration": 3000,
        },
      });
    } else {
      this.bfcNotificationService.showNotification({
        type: BfcNotificationType.SUCCESS,
        message: this.bfcTranslationService.translate("GLOBAL_MESSAGE_PAGE.SAVING_MESSAGE"),
        options: {
          "duration": 3000,
        },
      });
      this.globalMessageService.setGlobalMessage(
        fromMoment.toDate(), untilMoment.toDate(), this.messageDe, this.messageFr).pipe(
        takeUntil(this.unsubscribe),
      ).subscribe(
        () => {
          this.loadGlobalPublicMessage();
          this.bfcNotificationService.showNotification({
            type: BfcNotificationType.SUCCESS,
            message: this.bfcTranslationService.translate("GLOBAL_MESSAGE_PAGE.SAVE_SUCCESSFUL"),
            options: {
              "duration": 3000,
            },
          });
        },
        () => {
          this.bfcNotificationService.showNotification({
            type: BfcNotificationType.ERROR,
            message: this.bfcTranslationService.translate("GLOBAL_MESSAGE_PAGE.SAVE_FAIL"),
            options: {
              "duration": 3000,
            },
          });
        });
    }
  }

  public delete(): void {
    this.bfcNotificationService.showNotification({
      type: BfcNotificationType.SUCCESS,
      message: this.bfcTranslationService.translate("GLOBAL_MESSAGE_PAGE.DELETING_MESSAGE"),
      options: {
        "duration": 3000,
      },
    });
    this.globalMessageService.deleteGlobalMessage().pipe(takeUntil(this.unsubscribe)).subscribe(
      () => {
        this.loadGlobalPublicMessage();
        this.bfcNotificationService.showNotification({
          type: BfcNotificationType.SUCCESS,
          message: this.bfcTranslationService.translate("GLOBAL_MESSAGE_PAGE.DELETE_SUCCESSFUL"),
          options: {
            "duration": 3000,
          },
        });
        this.fromString = undefined;
        this.untilString = undefined;
        this.messageDe = undefined;
        this.messageFr = undefined;
      },
      () => {
        this.bfcNotificationService.showNotification({
          type: BfcNotificationType.ERROR,
          message: this.bfcTranslationService.translate("GLOBAL_MESSAGE_PAGE.DELETE_FAIL"),
          options: {
            "duration": 3000,
          },
        });
      });
  }

  private loadGlobalPublicMessage(): void {
    this.globalMessageService.loadPublicGlobalMessage().pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  private formatDate(date: Date): string {
    let m: any = moment(date);
    return m.isValid() ? m.format(this.dateFormat) : undefined;
  }
}
