import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";
import { BfcAutocompleteModule } from "@bfl/components/autocomplete";
import { BfcButtonModule } from "@bfl/components/button";
import { BfcExpansionModule } from "@bfl/components/expansion";
import { BfcFormModule } from "@bfl/components/form";
import { BfcFormFieldModule } from "@bfl/components/form-field";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { BfcInputModule } from "@bfl/components/input";
import { BfcLoadingModule } from "@bfl/components/loading";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { BFC_TRANSLATIONS, BfcTranslationModule } from "@bfl/components/translation";
import { NvtReplaceTelNrPipe } from "../shared/pipes/nvt-replace-tel-nr.pipe";
import { SharedModule } from "../shared/shared.module";
import { DisclaimerComponent } from "./components/disclaimer/disclaimer.component";
import { GlobalMessagePageComponent } from "./components/global-message-page/global-message-page.component";
import { HintsPageComponent } from "./components/hints-page/hints-page.component";
import { LocatorComponent } from "./components/locator/locator.component";
import { MapPageComponent } from "./components/map-page/map-page.component";
import { MapComponent } from "./components/map/map.component";
import { SinglePageRouterComponent } from "./components/single-page-router/single-page-router.component";
import { AreaService } from "./services/area.service";
import { GlobalMessageService } from "./services/global-message.service";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { InfoWindowService } from "./services/info-window.service";
import { SinglePageRouterService } from "./services/single-page-router.service";
import { OutageReportService } from "./services/outage-report.service";
import { OutageReportPageComponent } from "./components/outage-report-page/outage-report-page.component";
import { SupplyZoneRestApiService } from "./services/rest-api/supply-zone-rest-api.service";
import { BrowserFingerprintService } from "./services/browser-fingerprint.service";
import { LogService } from "./services/log.service";
import { LogRestApiService } from "./services/rest-api/log-rest-api.service";
import { BfcCheckboxModule } from "@bfl/components/checkbox";
import { BfcSelectModule } from "@bfl/components/select";
import { InfoWindowComponent } from "./components/info-window/info-window.component";
import {
    OutageReportConfirmationPageComponent
} from "./components/outage-report-confirmation-page/outage-report-confirmation-page.component";
import { GlobalMessageRestApiService } from "./services/rest-api/global-message-rest-api.service";
import { TrafoRestApiService } from "./services/rest-api/trafo-rest-api.service";
import { OutageReportRestApiService } from "./services/rest-api/outage-report-rest-api.service";
import { SupplyObjectService } from "./services/supply-object.service";
import { GoogleLocatorComponent } from "./components/google-locator/google-locator.component";
import { SelfServiceRestApiService } from "./services/rest-api/self-service-rest-api.service";
import { SelfServiceService } from "./services/self-service.service";
import { BfcNotificationModule } from "@bfl/components/notification";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { ThemeNameTransformService } from "./services/theme-name-transform.service";
import { DE_TRANSLATIONS } from "../config/translations/de";
import { FR_TRANSLATIONS } from "../config/translations/fr";
import { BfcBasePageLayoutModule } from "@bfl/components/base-page-layout";
import { SupplierRestApiService } from "./services/rest-api/supplier-rest-api.service";
import { BfcSinglePageLayoutFullWidthModule } from "@bfl/components/single-page-layout-full-width";
import { PolygonService } from "./services/polygon.service";

@NgModule({
    imports: [
        SharedModule,
        BrowserModule,
        BfcExpansionModule,
        BfcSinglePageLayoutModule,
        BfcButtonModule,
        BfcGoogleAnalyticsModule,
        BfcTranslationModule,
        BfcLoadingModule,
        BfcNotificationModule,
        BfcFormModule,
        BfcFormFieldModule,
        BfcInputModule,
        BfcCheckboxModule,
        BfcSelectModule,
        BfcAutocompleteModule,
        CommonModule,
        FlexLayoutModule,
        HttpClientModule,
        MatIconModule,
        BfcSinglePageLayoutFullWidthModule,
        BfcBasePageLayoutModule,
    ],
    declarations: [
        SinglePageRouterComponent,
        MapComponent,
        MapPageComponent,
        LocatorComponent,
        GoogleLocatorComponent,
        DisclaimerComponent,
        HintsPageComponent,
        GlobalMessagePageComponent,
        OutageReportPageComponent,
        OutageReportConfirmationPageComponent,
        InfoWindowComponent,
    ],
    entryComponents: [
        InfoWindowComponent,
    ],
    providers: [
        SinglePageRouterService,
        GlobalMessageRestApiService,
        LogRestApiService,
        OutageReportRestApiService,
        SupplyZoneRestApiService,
        TrafoRestApiService,
        SelfServiceRestApiService,
        SupplyObjectService,
        InfoWindowService,
        GoogleAnalyticsService,
        GlobalMessageService,
        AreaService,
        OutageReportService,
        BrowserFingerprintService,
        LogService,
        SelfServiceService,
        NvtReplaceTelNrPipe,
        BfcAuthenticationService,
        ThemeNameTransformService,
        SupplierRestApiService,
        PolygonService,
        {
            provide: BFC_TRANSLATIONS,
            useValue: {
                de: DE_TRANSLATIONS,
                fr: FR_TRANSLATIONS,
            },
            multi: true,
        },
    ],
    exports: [
        SinglePageRouterComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ],
})
export class NvtModule {
}
