<bfc-base-page-layout >
    <div class="back-button-default">
        <a (click)="singlePageRouterService.state.next('map')"><span>« {{ 'HINTS_PAGE.BACK_LINK' | bfcTranslate }}</span></a>
    </div>
    <div class="hints-content-wrapper" fxLayout="column" fxFlex="noshrink">
        <h1 class="hints-title">{{ 'HINTS_PAGE.TITLE' | bfcTranslate }}</h1>
        <p class="hints-content-text">{{content()}}</p>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'HINTS_PAGE.ACCORDION.PREPARATION.TITLE' | bfcTranslate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ul>
                <li>
                    <p [innerHtml]=" 'HINTS_PAGE.ACCORDION.PREPARATION.TEXT_1' | bfcTranslate: {'numberText': numberText()} | nvtReplaceTelNr | nvtReplaceCompanyName "></p>
                </li>
                <li>{{ 'HINTS_PAGE.ACCORDION.PREPARATION.TEXT_2' | bfcTranslate }}</li>
                <li>{{ 'HINTS_PAGE.ACCORDION.PREPARATION.TEXT_3' | bfcTranslate }}</li>
                <li>{{ 'HINTS_PAGE.ACCORDION.PREPARATION.TEXT_4' | bfcTranslate }}</li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'HINTS_PAGE.ACCORDION.ACTIONS.TITLE' | bfcTranslate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ul>
                <li><p [innerHtml]=" 'HINTS_PAGE.ACCORDION.ACTIONS.TEXT_1' | bfcTranslate "></p></li>
                <li><p [innerHtml]=" 'HINTS_PAGE.ACCORDION.ACTIONS.TEXT_2' | bfcTranslate | nvtReplaceTelNr | nvtReplaceCompanyName "></p></li>
                <li>{{ 'HINTS_PAGE.ACCORDION.ACTIONS.TEXT_3' | bfcTranslate }}<br/>
                    {{ 'HINTS_PAGE.ACCORDION.ACTIONS.TEXT_3_1' | bfcTranslate }}<br/>
                    {{ 'HINTS_PAGE.ACCORDION.ACTIONS.TEXT_3_2' | bfcTranslate }}
                </li>
                <li>{{ 'HINTS_PAGE.ACCORDION.ACTIONS.TEXT_4' | bfcTranslate }}</li>
                <li>{{ 'HINTS_PAGE.ACCORDION.ACTIONS.TEXT_5' | bfcTranslate }}</li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'HINTS_PAGE.ACCORDION.POST_BLACKOUT_ACTIONS.TITLE' | bfcTranslate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ul>
                <li>{{ 'HINTS_PAGE.ACCORDION.POST_BLACKOUT_ACTIONS.TEXT_1' | bfcTranslate }}</li>
                <li>{{ 'HINTS_PAGE.ACCORDION.POST_BLACKOUT_ACTIONS.TEXT_2' | bfcTranslate }}</li>
                <li>{{ 'HINTS_PAGE.ACCORDION.POST_BLACKOUT_ACTIONS.TEXT_3' | bfcTranslate }}</li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="bfc-margin-bottom-2">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'HINTS_PAGE.ACCORDION.HINTS.TITLE' | bfcTranslate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ul>
                <li>{{ 'HINTS_PAGE.ACCORDION.HINTS.TEXT_1' | bfcTranslate }}</li>
                <li>{{ 'HINTS_PAGE.ACCORDION.HINTS.TEXT_2' | bfcTranslate }}</li>
            </ul>
        </mat-expansion-panel>

        <div class="downloads bfc-padding-top-2" *ngIf="isBKW">
            <h2 class="hints-downloads-title">{{ 'HINTS_PAGE.DOWNLOADS.TITLE' | bfcTranslate }}</h2>
            <a href="{{ 'HINTS_PAGE.DOWNLOADS.HINTS_1_URL' | bfcTranslate }}" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_1' | bfcTranslate }}</a>
        </div>
        <div class="downloads" *ngIf="isBKW else otherRealms">
            <a href="https://www.bkw.ch/fileadmin/oneportal/outage/Stromausfall_DE.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_DE' | bfcTranslate }}</a>
            <a href="https://www.bkw.ch/fileadmin/oneportal/outage/Coupure_de_courant_FR.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_FR' | bfcTranslate }}</a>
            <a href="https://www.bkw.ch/fileadmin/oneportal/outage/Interruzione_della_corrente_IT.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_IT' | bfcTranslate }}</a>
            <a href="https://www.bkw.ch/fileadmin/oneportal/outage/Power_cut_EN.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_EN' | bfcTranslate }}</a>
            <a href="https://www.bkw.ch/fileadmin/oneportal/outage/Corte_de_electricidad_ES.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_ES' | bfcTranslate }}</a>
            <a href="https://www.bkw.ch/fileadmin/oneportal/outage/Corte_de_electricidade_PT.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_PT' | bfcTranslate }}</a>
            <a href="https://www.bkw.ch/fileadmin/oneportal/outage/Nderprerjeje_te_rrymes_elektrike_ALB.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_ALB' | bfcTranslate }}</a>
            <a href="https://www.bkw.ch/fileadmin/oneportal/outage/Nestane_struje_BCMS.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_BCMS' | bfcTranslate }}</a>
            <a href="https://www.bkw.ch/fileadmin/oneportal/outage/Power_cut_Amharisch.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_AMH' | bfcTranslate }}</a>
            <a href="https://www.bkw.ch/fileadmin/oneportal/outage/Power_cut_Tamil.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_TA' | bfcTranslate }}</a>
        </div>
        <ng-template #otherRealms>
            <div class="downloads bfc-padding-top-2">
                <h2 class="hints-downloads-title">{{ 'HINTS_PAGE.DOWNLOADS.TITLE' | bfcTranslate }}</h2>
                <a href="https://www.bkw.ch/fileadmin/oneportal/outage/{{theme}}/Stromausfall_DE.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_DE' | bfcTranslate }}</a>
                <a href="https://www.bkw.ch/fileadmin/oneportal/outage/{{theme}}/Coupure_de_courant_FR.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_FR' | bfcTranslate }}</a>
                <a href="https://www.bkw.ch/fileadmin/oneportal/outage/{{theme}}/Interruzione_della_corrente_IT.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_IT' | bfcTranslate }}</a>
                <a href="https://www.bkw.ch/fileadmin/oneportal/outage/{{theme}}/Power_cut_EN.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_EN' | bfcTranslate }}</a>
                <a href="https://www.bkw.ch/fileadmin/oneportal/outage/{{theme}}/Corte_de_electricidad_ES.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_ES' | bfcTranslate }}</a>
                <a href="https://www.bkw.ch/fileadmin/oneportal/outage/{{theme}}/Corte_de_electricidade_PT.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_PT' | bfcTranslate }}</a>
                <a href="https://www.bkw.ch/fileadmin/oneportal/outage/{{theme}}/Nderprerjeje_te_rrymes_elektrike_ALB.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_ALB' | bfcTranslate }}</a>
                <a href="https://www.bkw.ch/fileadmin/oneportal/outage/{{theme}}/Nestane_struje_BCMS.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_BCMS' | bfcTranslate }}</a>
                <a href="https://www.bkw.ch/fileadmin/oneportal/outage/{{theme}}/Power_cut_Amharisch.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_AMH' | bfcTranslate }}</a>
                <a href="https://www.bkw.ch/fileadmin/oneportal/outage/{{theme}}/Power_cut_Tamil.pdf" target="_blank"><bfe-icon name="bfc-icon-download" class="bfe-icon-24 bfc-icon-download bfc-padding-bottom"></bfe-icon>{{ 'HINTS_PAGE.DOWNLOADS.HINTS_TA' | bfcTranslate }}</a>
            </div>
        </ng-template>

        <div class="back-button-default" fxLayout fxLayoutAlign="start">
            <button mat-button color="primary" class="bfc-button-hero" onclick="window.scroll(0,0)"
                (click)="singlePageRouterService.state.next('map')">
                {{ 'HINTS_PAGE.BACK_BUTTON' | bfcTranslate }}
            </button>
        </div>
    </div>
</bfc-base-page-layout>
