<app-map-page fxLayout="column" fxFlex="auto"
          [class.hidden]="singlePageRouterService.state.getValue() !== 'map'">
</app-map-page>
<app-hints-page fxLayout="column" fxFlex="auto"
            [class.hidden]="singlePageRouterService.state.getValue() !== 'hints'">
</app-hints-page>
<app-outage-report-page fxLayout="column" fxFlex="auto"
            [class.hidden]="singlePageRouterService.state.getValue() !== 'outage-report'">
</app-outage-report-page>
<app-outage-report-confirmation-page fxLayout="column" fxFlex="auto"
            [class.hidden]="singlePageRouterService.state.getValue() !== 'outage-report-confirmation'">
</app-outage-report-confirmation-page>
<app-global-message-page fxLayout="column" fxFlex="auto"
                     [class.hidden]="singlePageRouterService.state.getValue() !== 'global-message'">
</app-global-message-page>
