<bfc-base-page-layout>

    <div class="back-button-default">
        <a (click)="singlePageRouterService.state.next('map')"><span>« {{ 'GLOBAL_MESSAGE_PAGE.BACK_LINK' | bfcTranslate }}</span></a>
    </div>

    <div fxFlex="noshrink">
        <h1>{{ 'GLOBAL_MESSAGE_PAGE.TITLE' | bfcTranslate }}</h1>
        <div class="bfc-form">

            <div class="bfc-form-row-50-50">
                <mat-form-field>
                    <mat-label>{{ ('GLOBAL_MESSAGE_PAGE.FROM' | bfcTranslate) + ' (' + this.dateFormat + ')' }}</mat-label>
                    <input matInput type="text" [(ngModel)]="fromString"/>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ ('GLOBAL_MESSAGE_PAGE.UNTIL' | bfcTranslate) + ' (' + this.dateFormat + ')' }}</mat-label>
                    <input matInput type="text" [(ngModel)]="untilString"/>
                </mat-form-field>
            </div>

            <div class="bfc-form-row bfc-margin-bottom-2">
                <mat-form-field>
                    <mat-label>{{ 'GLOBAL_MESSAGE_PAGE.MESSAGE_DE' | bfcTranslate }}</mat-label>
                <textarea matInput #messageDeControl maxlength="300" [(ngModel)]="messageDe">
                </textarea>
                    <mat-hint align="end">{{messageDeControl.value.length}} / 300</mat-hint>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'GLOBAL_MESSAGE_PAGE.MESSAGE_FR' | bfcTranslate }}</mat-label>
                <textarea matInput #messageFrControl maxlength="300" [(ngModel)]="messageFr">
                </textarea>
                    <mat-hint align="end">{{messageFrControl.value.length}} / 300</mat-hint>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start">
                <button mat-button color="primary" class="bfc-button-hero" (click)="save()">
                    <span>{{ 'GLOBAL_MESSAGE_PAGE.SAVE' | bfcTranslate }}</span>
                </button>
                <button mat-button color="warn" class="bfc-button-secondary" (click)="delete()">
                    <span>{{ 'GLOBAL_MESSAGE_PAGE.DELETE' | bfcTranslate }}</span>
                </button>
                <button mat-button color="primary" class="bfc-button-secondary" onclick="window.scroll(0,0)"
                    (click)="singlePageRouterService.state.next('map')">
                    <span>{{ 'GLOBAL_MESSAGE_PAGE.BACK_BUTTON' | bfcTranslate }}</span>
                </button>
            </div>

        </div>
    </div>

</bfc-base-page-layout>
