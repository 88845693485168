import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
  BfcAuthenticationInterceptor,
} from "@bfl/components/authentication";
import { BFC_CONFIGURATIONS, BfcConfigurationModule, BfcConfigurationService } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsModule, BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { BfcTranslationModule, BfcTranslationService } from "@bfl/components/translation";
import { NvtModule } from "./nvt/nvt.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";
import { configurationList } from "./config/configuration";
import { NvtReplaceCompanyNamePipe } from "./shared/pipes/nvt-replace-company-name.pipe";
import { LanguageCookieService } from "./service/language-cookie.service";
import { AppRoutingModule } from "./app-routing.module";
import { initializeGoogleMaps } from "./core/initialization/initializer-googlemaps";

@NgModule({
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    NvtModule,
    BfcTranslationModule,
    BfcGoogleAnalyticsModule,
    BfcConfigurationModule,
    FlexLayoutModule,
    AppRoutingModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BfcAuthenticationInterceptor,
      multi: true,
    },
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeGoogleMaps,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue:  configurationList,
      multi: true,
    },
    NvtReplaceCompanyNamePipe,
    LanguageCookieService,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private bfcTranslateService: BfcTranslationService,
    private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    private bfcConfigurationService: BfcConfigurationService,
    private languageCookieService: LanguageCookieService) {

    // Overwrite the language handling from BFC for theme Lagoule
    // - The cookie is set for domain lagoule.ch
    // - Default language for Lagoule is fr
    if (bfcConfigurationService.environment.theme === bfcConfigurationService.configuration.lagouleTheme) {
      if (this.languageCookieService.getCookieLanguage() === undefined) {
        const lagouleDefaultLang = "fr";
        this.languageCookieService.setCookieLanguage(lagouleDefaultLang);
        bfcTranslateService.changeLanguage(lagouleDefaultLang);
      }
    }
    this.loadGoogleMapScript(bfcTranslateService.language);

    bfcGoogleAnalyticsService.initGoogleTagManager(bfcConfigurationService.configuration.googleTagManagerContainerId);
    bfcGoogleAnalyticsService.initGoogleAnalytics(bfcConfigurationService.configuration.googleAnalyticsTrackingId);

    const globalNavigation = document.getElementsByTagName("bfe-global-navigation")[0];
    const contactUrl = bfcConfigurationService.configuration.contactUrls;
    globalNavigation.setAttribute("service-custom-items", JSON.stringify([
      {
        "key": "contact",
        "titleTranslatable": "SERVICE_NAV.CONTACT",
        "url": contactUrl,
      },
    ]));

    const footer = document.getElementsByTagName("bfe-footer")[0];
    footer.setAttribute("contact-url", JSON.stringify(contactUrl));
  }

  private loadGoogleMapScript(language: string) {
    const key = "AIzaSyDyvSvXvSmKZeRYnG6jjrsvnxI1WYUIR_c"; // INFO this key is taken from lightning-outage...
    const scriptSrc = "https://maps.google.com/maps/api/js?language=" + language + "&key=" + key + "&callback=googleMapsCallback";
    const scriptTag = document.createElement("script");
    scriptTag.setAttribute("src", scriptSrc);
    scriptTag.setAttribute("async", "");
    document.head.appendChild(scriptTag);
  }
}
