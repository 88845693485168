<div class="info-window-content" *ngIf="supplyObject">
    <span class="info-window-content-title">{{ translatedTitle }}</span>
    <p *ngIf="translatedText && this.supplyObject.supplyState">{{ translatedText }}</p>
    <p *ngIf="translatedText && !this.supplyObject.supplyState && !this.supplyObject.supplierUrl">
      {{ translatedText }} {{ (this.supplyObject.supplierName) ?  this.supplyObject.supplierName : "UNKNOWN" | bfcTranslate }}
    </p>
    <p *ngIf="translatedText && !this.supplyObject.supplyState && this.supplyObject.supplierUrl">
      {{ translatedText }} <a [href]="this.supplyObject.supplierUrl" target="_blank">{{ this.supplyObject.supplierName }}</a>
    </p>
    <div fxLayout.xs="column" fxLayout.gt-xs="row" *ngIf="isSuppliedOrBkwSupplier() && !supplyObject.hasEnoughClicks()">
        <a (click)="singlePageRouterService.state.next('outage-report')"
           *ngIf="isSuppliedOrBkwSupplier()"><b>{{ 'MAP_PAGE.OUTAGE_REPORT' | bfcTranslate }}</b></a>
        <div [ngClass.gt-xs]="{'info-window-link ': true}"> {{ 'MAP_PAGE.OR' | bfcTranslate }} </div>
        <a href="tel:{{ this.bfcConfigurationService.configuration.telNr }}" (click)="tel()"
           *ngIf="isSuppliedOrBkwSupplier()"><b>{{ this.bfcConfigurationService.configuration.telNr }}</b></a>
    </div>
    <a (click)="singlePageRouterService.state.next('outage-report')"
        *ngIf="supplyObject.supplyState === SupplyState.Failure"><b class="blue-link">{{ 'MAP_PAGE.OUTAGE_DETAIL_REPORT' | bfcTranslate }}</b></a>
</div>
