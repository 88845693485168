export const FR_TRANSLATIONS = {
  "PAGE_TITLE": "Services {{companyName}} - Coupures de courant",
  "PAGE_DESCRIPTION": "Une coupure de courant chez vous? Vous trouverez ici la liste de toutes les coupures actuelles dans la zone de desserte {{companyName}}.",
  "ORIGIN_LABEL": "Coupures de courant",
  "ORIGIN_LINK": "https://www.bkw.ch/fr/clients-prives/service-clients/",
  "DISCLAIMER": "Les câbles et installations électriques peuvent être sous tension. Tout contact vous met en danger de mort!",
  "PLEASE_WAIT": "Veuillez attendre svp",
  "UNKNOWN": "inconnu",
  "MAP_PAGE": {
    "TITLE": "Coupures de courant",
    "USE_CURRENT_LOCATION": "Utiliser le site actuel",
    "HINTS_LINK": "Conseils",
    "CONTACT_LINK": "Vers les informations de contact",
    "SHOW_POLYGONS": "Afficher tous les circuits de station de transformation",
    "OUTAGE_REPORT_LINK": "Signaler une coupure de courant",
    "GLOBAL_MESSAGE_LINK": "Modifier le message global",
    "NO_BLACKOUTS_MESSAGE": "Aucun dérangement ne nous est connu.",
    "OUTAGE_REPORT": "Signaler une coupure de courant",
    "OR": "ou",
    "OUTAGE_DETAIL_REPORT": "Signaler l'origine de la panne",
    "INFO_WINDOW": {
      "NO_BLACKOUT": "Aucune panne de courant connue dans la région.",
      "PLANNED_BLACKOUT": "Exécuton de travaux de maintenance planifiés entre {{disconnectionStartTime}} et {{disconnectionEndTime}} heures. Merci pour votre compréhension.",
      "UNPLANNED_BLACKOUT": "Nous sommes en train de rétabllir le courant. Merci pour votre compréhension.",
      "COUNT_TEXT_1": "Zone de station transformatrice a été cherchée",
      "COUNT_TEXT_2": "{{clickCount}} pendant les 7 dernières minutes.",
    },
    "INFO_WINDOW_TITLE": {
      "NO_BLACKOUT": "Aucun dérangement connu",
      "PLANNED_BLACKOUT": "Dérangements possibles",
      "UNPLANNED_BLACKOUT": "Coupure de courant",
    },
  },
  "LOCATOR": {
    "PLACEHOLDER": "Entrer l'adresse (Rue, Numéro et lieu)",
    "PLACEHOLDER_LAGOULE": "Entrer code postal",
    "NOT_IN_SUPPLY_AREA": "Ce lieu est hors de la zone de desserte de {{companyName}}",
    "SUPPLY_AREA_SUPPLIED_BY": "Ce lieu est desservi par:",
    "NOT_IN_SUPPLY_AREA_TITLE": "Hors de la zone couverture",
  },
  "HINTS_PAGE": {
    "TITLE": "Conseils",
    "CONTENT": "La sécurité d'approvisionnement est essentielle pour {{companyName}}. Afin de fournir de l'électricité de manière fiable et sans interruption à ses clients, ses collaborateurs s'engagent 24h/24. Des coupures de courant peuvent toutefois survenir dans des cas exceptionnels. C'est pourquoi il est important d'être bien préparé à une éventuelle interruption de courant et de savoir comment réagir dans un tel cas.",
    "CONTENT_AEK_ONYX": "Un dysfonctionnement de la fourniture d'énergie électrique peut être à la cause de différents événements. Pour rassurer qu'une coupure de courant soit réparée au plus vite possible, nous dépendons de votre assistance précieuse. Veuillez nous indiquer en détail ce que vous avez ob-servé en cas d'une panne.",
    "ACCORDION": {
      "PREPARATION": {
        "TITLE": "Comment puis-je me préparer?",
        "TEXT_1": "Gardez à portée de main ou enregistrez dans votre téléphone portable le numéro du <strong>service des dérangements de {{companyName}} (<a href='tel:{{telNr}}' onclick='tel(null)'>{{telNr}}</a>)</strong>, ainsi que le numéro de téléphone de votre gérance et / ou de votre installateur-électricien.",
        "TEXT_2": "Familiarisez-vous avec les fonctions des fusibles et des disjoncteurs de votre logement.",
        "TEXT_3": "Ayez toujours à portée de main une lampe de poche et des piles de rechange.",
        "TEXT_4": "Sauvegardez régulièrement vos données informatiques.",
      },
      "ACTIONS": {
        "TITLE": "Que dois-je faire en cas de coupure de courant?",
        "TEXT_1": "Eteignez manuellement les appareils suivants: plaques de cuisson, fer à repasser, chauffage électriques, perceuse, scie, aspirateur, etc. <strong>Attention:</strong> s'ils ne sont pas débranchés ou éteints, ces appareils se remettent en marche dès que le courant est rétabli, ce qui peut s'avérer dangereux!",
        "TEXT_2": "Vérifiez si votre voisin est lui aussi touché par la coupure de courant. Si la panne électrique concerne plusieurs habitations, contactez le <strong>service des dérangements de {{companyName}} au <a href='tel:{{telNr}}'>{{telNr}}</a>.</strong>",
        "TEXT_3": "Si vos voisins ont du courant:",
        "TEXT_3_1": "1. Contrôlez vos fusibles et vos disjoncteurs,",
        "TEXT_3_2": "2. Contactez votre gérance ou votre installateur-électricien.",
        "TEXT_4": "Informez les personnes âgées et les enfants de la panne de courant.",
        "TEXT_5": "N'ouvrez pas votre réfrigérateur ni votre congélateur afin d'éviter toute perte de froid.",
      },
      "POST_BLACKOUT_ACTIONS": {
        "TITLE": "Que dois-je faire une fois le courant rétabli?",
        "TEXT_1": "Assurez-vous qu'aucun appareil n'est susceptible d'entraîner des dommages (p. ex. plaques de cuisson qui chauffent).",
        "TEXT_2": "Faites le tour des lieux à des fins de contrôle.",
        "TEXT_3": "En cas de panne de longue durée (supérieure à 12 heures), vérifiez le contenu de votre réfrigérateur et de votre congélateur et jetez les aliments le cas échéant.",
      },
      "HINTS": {
        "TITLE": "Informations concernant les téléphones fixes et les téléphones portables",
        "TEXT_1": "Les téléphones branchés sur le secteur se coupent immédiatement en cas de panne de courant.",
        "TEXT_2": "En temps normal, le réseau de téléphonie mobile (et donc Internet sur les smartphones) continue à fonctionner. Très rarement, par exemple lors de pannes de courant de grande envergure, il arrive toutefois qu'il ne soit plus possible d'utiliser les téléphones portables.",
      },
      "NUMBER_TEXT": "",
      "NUMBER_TEXT_AEK": "",
    },
    "DOWNLOADS": {
      "TITLE": "Téléchargement",
      "HINTS_1": "Stromausfall: Verhaltenstipps für Ihre Sicherheit PDF",
      "HINTS_1_URL": "https://www.bkw.ch/fileadmin/oneportal/outage/Stromausfall_Verhaltenstipps_fr.pdf",
      "HINTS_DE": "Stromausfall - Was mache ich bei einem Stromausfall PDF",
      "HINTS_FR": "Coupure de courant - Que dois-je faire en cas de coupure de courant PDF",
      "HINTS_IT": "Interruzione della corrente - Cosa devo fare in caso di interruzione della corrente PDF",
      "HINTS_EN": "Power cut - What should I do in the case of a power cut PDF",
      "HINTS_ES": "Corte de electricidad - Qué debo hacer si se produce un corte de electricidad PDF",
      "HINTS_PT": "Corte de electricidade - O que devo fazer no caso de um corte de electricidade PDF",
      "HINTS_ALB": "Ndërprerjeje te rrymës elektrike - Cfarë duhet të bëj në rast ndërprerjeje te rrymës elektrike PDF",
      "HINTS_BCMS": "Nestane struje - Sta treba da uradim ako nestane struje PDF",
      "HINTS_AMH": "የሀይም መቋረጥ ቢኖር ምን ማድረግ እችላለሁ?",
      "HINTS_TA": "மின் வெட்டு ஏற்பட்டால் நான் என்ன செய்ய வேண்டும்?",
    },
    "BACK_BUTTON": "Vers l'aperçu",
    "BACK_LINK": "Vers l'aperçu",
  },
  "OUTAGE_REPORT_PAGE": {
    "TITLE": "Signaler une coupure de courant",
    "LOCATION": "Lieu de la coupure de courant",
    "CONTACT_DATA": "Coordonnées (Optionnel)",
    "LAST_NAME": "Nom",
    "FIRST_NAME": "Prénom",
    "PHONE": " Numéro de téléphone",
    "EMAIL": "E-mail",
    "ZIP_AND_CITY": "NPA et lieu",
    "STREET": "Nom de rue",
    "HOUSE_NO": "n°",
    "MY_LOCATION": "Utiliser le site actuel",
    "MESSAGE": "Origine de la panne",
    "BACK_BUTTON": "Vers l'aperçu",
    "BACK_LINK": "Vers l'aperçu",
    "REASON_KNOWN": "Je connais l'origine de la panne",
    "UPLOAD_BUTTON": "Téléchargement d'image (taille maximale: 4MB)",
    "SUBMIT_BUTTON": "Signaler une coupure de courant",
    "MESSAGE_TOO_LONG": "Le message indiqué est trop long.",
    "FILE_TOO_BIG": "Taille maximale dépassée, veuillez choisir une image plus petite.",
    "SAVE_FAIL": "La coupure de courant n'a pas pu être sauvegardé.",
    "PRIVACY_AGREEMENT_BKW": "J’accepte par la présente les <a href=\"https://www.bkw.ch/fileadmin/oneportal/mybkw/documents/AGB_Nutzungsbedingungen_Online_Kundencenter_A4_F_BKW_Web.pdf\" target=\"_blank\">conditions d’utilisation</a>, la <a href=\"https://www.bkw.ch/fr/special-navigations/rechtliches/declaration-de-confidentialite\" target=\"_blank\">déclaration de confidentialité</a> ainsi que l'utilisation de mes données.",
  },
  "OUTAGE_REPORT_CONFIRMATION_PAGE": {
    "TITLE": "Merci beaucoup",
    "CONFIRMATION": "Votre notification de dérangement sera traité le plus vite possible.",
    "BACK_BUTTON": "Vers l'aperçu",
    "BACK_LINK": "Vers l'aperçu",
  },
  "GLOBAL_MESSAGE_PAGE": {
    "TITLE": "Modifier le message global",
    "FROM": "de",
    "UNTIL": "à",
    "MESSAGE_DE": "Message (allemand)",
    "MESSAGE_FR": "Message (français)",
    "SAVE": "Sauvegarder",
    "DELETE": "Supprimer",
    "BACK_BUTTON": "Vers l'aperçu",
    "BACK_LINK": "Vers l'aperçu",
    "TIME_RANGE_INVALID": "La période indiquée n'est pas valide.",
    "MESSAGE_TOO_LONG": "Le message indiqué est trop long.",
    "SAVING_MESSAGE": "Le message global est en train d'être sauvegardé...",
    "SAVE_SUCCESSFUL": "Le message global a été sauvegardé.",
    "SAVE_FAIL": "Le message global n'a pas pu être sauvegardé.",
    "DELETING_MESSAGE": "Le message global est en train d'être supprimé...",
    "DELETE_SUCCESSFUL": "Le message global a été supprimé.",
    "DELETE_FAIL": "Le message global n'a pas pu être supprimé.",
  },
  "LAGOULE": {
    "HEADER": {
      "CONTACT": "Contact",
      "BKW_LINK": "Lien vers le site Internet de BKW Energie SA",
      "FR": "FR",
      "FR_TITLE": "Français",
      "DE": "DE",
      "DE_TITLE": "Allemand",
    },
  },
};