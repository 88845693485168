import { IMarkerClusterer } from "./IMarkerClusterer";
import { SupplyObject } from "./SupplyObject";
import Map = google.maps.Map;

export class City extends SupplyObject {

  private zip: number;

  private city: string;

  constructor(id: number,
    zip: number,
    city: string,
    map: Map,
    markerClusterer: IMarkerClusterer) {
    super(id, map, markerClusterer, null, null);
    this.zip = zip;
    this.city = city;
  }

  public getDisplayText(): string {
    return this.zip + " " + this.city;
  }

  public getZip(): number {
    return this.zip;
  }

  public getCity(): string {
    return this.city;
  }

  public matchesSearchText(searchText: string): boolean {
    let isNumber: boolean = +searchText === +searchText;
    if (isNumber) {
      return (this.zip + "").startsWith(searchText);
    } else {
      return this.getDisplayText().toLowerCase().indexOf(searchText) >= 0;
    }
  }

}
