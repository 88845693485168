import LatLng = google.maps.LatLng;
import Map = google.maps.Map;

export class MapUtils {
  public static panToWithOffset(map: Map, latLng: LatLng, offsetX: number, offsetY: number) {
    let projection = map.getProjection();

    const point = projection.fromLatLngToPoint(latLng);

    const latLngWithOffset = projection.fromPointToLatLng(new google.maps.Point(
      point.x - offsetX / Math.pow(2, map.getZoom()),
      point.y - offsetY / Math.pow(2, map.getZoom()),
    ));

    map.panTo(latLngWithOffset);
  }
}
