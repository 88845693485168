import { Injectable } from "@angular/core";

@Injectable()
export class LanguageCookieService {

  private cname = "BKW_OP_USER_LANGUAGE";

  public getCookieLanguage(): string {
    const name = this.cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return undefined;
  }

  // persist
  public setCookieLanguage(language : string) {
    const expiresDate = new Date();
    expiresDate.setDate(expiresDate.getDate() + 30);
    function setCookie(name, value, expires, domain) {
      document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/;domain=${domain}`;
    }
    setCookie(this.cname, language, expiresDate, ".lagoule.ch");
    setCookie(this.cname, language, expiresDate, "localhost");

  }
}
