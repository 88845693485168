import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { Person } from "../../common/model/person";

@Injectable()
export class SelfServiceRestApiService {

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  public getPerson(): Observable<Person> {
    return this.httpClient.get<Person>(this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me/person",
      { params: new HttpParams().set("expandAddresses", "true") });
  }

}
