import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Area } from "../common/Area";
import { map, share } from "rxjs/operators";
import { ISupplyZoneResponse, SupplyZoneRestApiService } from "./rest-api/supply-zone-rest-api.service";
import { ITrafoPolygonResponse, TrafoRestApiService } from "./rest-api/trafo-rest-api.service";
import { SupplyObject } from "../common/SupplyObject";
import { Trafo } from "../common/Trafo";
import { City } from "../common/City";
import { SupplyState } from "../common/SupplyState";

interface IdMap<T> {
  [id: number]: T;
}

@Injectable()
export class AreaService {
  private idToSupplyObjectAreaMap: IdMap<Area> = {};

  private idToSupplyObjectAreaObservableMap: IdMap<Observable<Area>> = {};

  constructor(private supplyZoneRestApi: SupplyZoneRestApiService,
    private trafoRestApi: TrafoRestApiService) {
  }

  public hasArea(supplyObject: SupplyObject): boolean {
    return !!this.idToSupplyObjectAreaObservableMap[supplyObject.id];
  }

  public getArea(supplyObject: SupplyObject): Observable<Area> {
    if (supplyObject.id === null) {
      return of(null);
    }

    let area: Area = this.idToSupplyObjectAreaMap[supplyObject.id];
    if (area) {
      return of(area);
    }

    let ongoingAreaRequest: Observable<Area> = this.idToSupplyObjectAreaObservableMap[supplyObject.id];
    if (ongoingAreaRequest) {
      return ongoingAreaRequest;
    }

    let areaRequest;
    if (supplyObject instanceof City) {
      areaRequest = this.supplyZoneRestApi.getSupplyZoneByCity(supplyObject).pipe(
        map((supplyZoneResponse: ISupplyZoneResponse) => {
          if (supplyZoneResponse) {
            return this.constructMultiArea(
              supplyZoneResponse.area, supplyObject.supplyState, supplyObject.id, supplyObject.clickCount);
          } else {
            // eslint-disable-next-line no-console
            console.error("No Supply Zone for id " + supplyObject.id + " found!");
          }
        }),
        share(),
      );
    } else if (supplyObject instanceof Trafo) {
      areaRequest = this.trafoRestApi.getTrafoByNumber(supplyObject.id).pipe(
        map((trafoPolygonResponse: ITrafoPolygonResponse) => {
          if (trafoPolygonResponse) {
            return this.constructArea(
              trafoPolygonResponse.area, supplyObject.supplyState, supplyObject.id, supplyObject.clickCount);
          } else {
            // eslint-disable-next-line no-console
            console.error("No Trafo for trafo number " + supplyObject.id + " found!");
          }
        }),
        share(),
      );
    }
    this.idToSupplyObjectAreaObservableMap[supplyObject.id] = areaRequest;
    return areaRequest;
  }

  private constructArea(polygon: number[][][], supplyState: SupplyState, id: number, clickCount?: number): Area {
    let area: Area = new Area(polygon);
    area.changeStyle(supplyState, clickCount);
    this.idToSupplyObjectAreaMap[id] = area;
    return area;
  }

  private constructMultiArea(multipolygon: number[][][][],
    supplyState: SupplyState,
    id: number,
    clickCount?: number): Area {
    //TODO Refactor as this creates just the first Polygon from the multi polygon
    let area: Area = new Area(multipolygon[0]);
    area.changeStyle(supplyState, clickCount);
    this.idToSupplyObjectAreaMap[id] = area;
    return area;
  }

}
