import { AfterViewInit, Component, ViewEncapsulation } from "@angular/core";
import { DomSanitizer, Meta, Title } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcTranslationService } from "@bfl/components/translation";
import { NvtReplaceCompanyNamePipe } from "./shared/pipes/nvt-replace-company-name.pipe";
import { LogService } from "./nvt/services/log.service";
import { LogEvent } from "./nvt/common/LogEvent";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements AfterViewInit {

  constructor(private translateService: BfcTranslationService,
    private bfcConfigurationService: BfcConfigurationService,
    private nvtReplaceCompanyNamePipe: NvtReplaceCompanyNamePipe,
    public logService: LogService,
    matIconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    title: Title,
    meta: Meta) {
    title.setTitle(nvtReplaceCompanyNamePipe.transform(translateService.translate("PAGE_TITLE")));
    meta.addTag({
      name: "description",
      content: nvtReplaceCompanyNamePipe.transform(translateService.translate("PAGE_DESCRIPTION")),
    }, true);

    matIconRegistry.addSvgIcon("upload", sanitizer.bypassSecurityTrustResourceUrl("assets/img/BKW_Icon_Upload.svg"));
  }

  ngAfterViewInit(): void {
    this.logService.log(LogEvent.Aufruf, null, null);
  }
}
