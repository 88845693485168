import { NgModule } from "@angular/core";
import { NvtReplaceCompanyNamePipe } from "./pipes/nvt-replace-company-name.pipe";
import { NvtReplaceTelNrPipe } from "./pipes/nvt-replace-tel-nr.pipe";

@NgModule({
  imports: [],
  declarations: [
    NvtReplaceTelNrPipe,
    NvtReplaceCompanyNamePipe,
  ],
  providers: [],
  exports: [
    NvtReplaceTelNrPipe,
    NvtReplaceCompanyNamePipe,
  ],
})
export class SharedModule {
}
