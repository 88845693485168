import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IOutageReportData, OutageReportRestApiService } from "./rest-api/outage-report-rest-api.service";
import { SupplyObject } from "../common/SupplyObject";

@Injectable()
export class OutageReportService {

  public supplyObject: BehaviorSubject<SupplyObject> = new BehaviorSubject<SupplyObject>(null);

  constructor(private outageReportRestApi: OutageReportRestApiService) {
  }

  public sendOutageReport(outageReportData: IOutageReportData): Observable<any> {
    return this.outageReportRestApi.sendOutageReport(outageReportData);
  }

  public sendOutageReportMultipartForm(outageReportMultipartFormData): Observable<any> {
    return this.outageReportRestApi.sendOutageReportMultipartForm(outageReportMultipartFormData);
  }
}
