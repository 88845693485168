export const GENERAL_ENV = {
  // BKW 
  googleTagManagerContainerId: "GTM-N4VBJZH",
  googleAnalyticsTrackingId: "UA-58712110-1",
  companyName: "BKW",
  telNr: "0844 121 175",

  // common settings
  roleNvt: "ROLE_NVT",
  roleNvtLagoule: "ROLE_NVT_LAGOULE",
  roleNvtInternal: "ROLE_NVT_INTERNAL",
  lagouleTheme: "la-goule",
};