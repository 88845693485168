import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import * as Fingerprint2 from "fingerprintjs2";

@Injectable()
export class BrowserFingerprintService {

  public browserHash$ = new ReplaySubject<string>(1);

  constructor() {
    // a timeout is needed to get consistent IDs
    setTimeout(() => {
      Fingerprint2.get(hash => {
        this.browserHash$.next(String(Fingerprint2.x64hash128(hash.join(""), 31)));
      });
    }, 500);
  }

}
