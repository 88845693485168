import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { ThemeNameTransformService } from "../theme-name-transform.service";

@Injectable()
export class GlobalMessageRestApiService {
  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    private realmNameTransformService: ThemeNameTransformService) {
  }

  public getPublicGlobalMessage(): Observable<IPublicGlobalMessageResponse> {
    const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
    return this.httpClient.get<IPublicGlobalMessageResponse>(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/globalMessage", { params });
  }

  public getGlobalMessage(): Observable<IGlobalMessageData> {
    const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
    return this.httpClient.get<IGlobalMessageData>(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/sec/getGlobalMessage", { params });
  }

  public setGlobalMessage(globalMessageData: IGlobalMessageData): Observable<any> {
    const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
    return this.httpClient.post(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/sec/setGlobalMessage", globalMessageData, { params });
  }

  public deleteGlobalMessage() {
    const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
    return this.httpClient.delete(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/sec/deleteGlobalMessage", { params });
  }

}

export interface IPublicGlobalMessageResponse {
  messageDe: string;
  messageFr: string;
}

export interface IGlobalMessageData {
  messageDe: string;
  messageFr: string;
  validFrom: string;
  validTill: string;
}
