import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { SupplyState } from "../../common/SupplyState";
import { ThemeNameTransformService } from "../theme-name-transform.service";
import { SupplierDto } from "../../common/SupplierDto";
import LatLng = google.maps.LatLng;

@Injectable()
export class TrafoRestApiService {

    constructor(private httpClient: HttpClient,
                private bfcConfigurationService: BfcConfigurationService,
                private realmNameTransformService: ThemeNameTransformService) {
    }

    public getTrafoByNumber(number: number): Observable<ITrafoPolygonResponse> {
        const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
        return this.httpClient.get<ITrafoPolygonResponse>(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/trafo/" + number, {params});
    }

    public getTrafoByCoordinates(latLng: LatLng): Observable<ITrafoPolygonResponse> {
        const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
        return this.httpClient.get<ITrafoPolygonResponse>(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/trafo/" + latLng.lat() + "-" + latLng.lng(), {params});
    }

    public getTrafoStates(): Observable<ITrafoStateResponse[]> {
        const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
        return this.httpClient.get<ITrafoStateResponse[]>(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/trafo/state", {params});
    }

    public getTrafoStatesAndClickCounts(): Observable<ITrafoStateResponse[]> {
        const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
        return this.httpClient.get<ITrafoStateResponse[]>(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/trafo/sec/state", {params});
    }

}

export interface ITrafoPolygonResponse {
    trafoNumber: number;
    supplier: string;
    area: number[][][];
}

export interface ITrafoOrSupplierPolygonResponse {
    trafoPolygon: ITrafoPolygonResponse;
    supplierDtos: SupplierDto[];
}

export interface ITrafoStateResponse {
    trafoNumber: number;
    trafoName: string;
    supplyState: SupplyState;
    disconnectionStartTime: string;
    disconnectionEndTime: string;
    affectedCustomers: number;
    longitude: number;
    latitude: number;
    clickCount?: number;
}
