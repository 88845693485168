import { Component, OnInit } from "@angular/core";
import { SinglePageRouterService } from "../../services/single-page-router.service";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { SupplyState } from "../../common/SupplyState";
import { BfcTranslationService } from "@bfl/components/translation";
import { LogService } from "../../services/log.service";
import { LogEvent } from "../../common/LogEvent";
import { SupplyObject } from "../../common/SupplyObject";
import { NvtReplaceCompanyNamePipe } from "../../../shared/pipes/nvt-replace-company-name.pipe";
import { OutageReportService } from "../../services/outage-report.service";
import * as moment from "moment";
import { Moment } from "moment";

@Component({
  selector: "app-info-window",
  templateUrl: "./info-window.component.html",
  styleUrls: ["./info-window.component.scss"],
})
export class InfoWindowComponent implements OnInit {

  public supplyObject: SupplyObject;

  public translatedText: string;

  public SupplyState = SupplyState;

  public translatedTitle: string;

  private readonly onlyTimeFormat: string = "HH:mm";

  private readonly dateTimeFormat: string = "DD.MM.YYYY HH:mm";

  private readonly bkwSupplierName: string = "BKW Energie AG";

  constructor(public bfcConfigurationService: BfcConfigurationService,
    public singlePageRouterService: SinglePageRouterService,
    private bfcTranslationService: BfcTranslationService,
    private outageReportService: OutageReportService,
    private logService: LogService,
    private nvtReplaceCompanyNamePipe: NvtReplaceCompanyNamePipe) {
  }

  ngOnInit(): void {
    if (this.supplyObject) {
      let key;
      let translatedKey;

      if (this.supplyObject.hasEnoughClicks()) {
        this.translatedTitle = this.supplyObject.getDisplayText();
        this.translatedText = `${this.bfcTranslationService.translate("MAP_PAGE.INFO_WINDOW.COUNT_TEXT_1")} 
        ${this.bfcTranslationService.translate("MAP_PAGE.INFO_WINDOW.COUNT_TEXT_2",
    { clickCount: this.supplyObject.clickCount.toString() })}`;
      } else {
        switch (this.supplyObject.supplyState) {
          case SupplyState.Supplied:
            translatedKey = "MAP_PAGE.INFO_WINDOW_TITLE.NO_BLACKOUT";
            break;
          case SupplyState.Disconnection:
            key = "MAP_PAGE.INFO_WINDOW.PLANNED_BLACKOUT";
            translatedKey = "MAP_PAGE.INFO_WINDOW_TITLE.PLANNED_BLACKOUT";
            break;
          case SupplyState.Failure:
            key = "MAP_PAGE.INFO_WINDOW.UNPLANNED_BLACKOUT";
            translatedKey = "MAP_PAGE.INFO_WINDOW_TITLE.UNPLANNED_BLACKOUT";
            break;
          case null:
          case undefined:
            if (this.supplyObject.supplierName === this.bkwSupplierName) {
              translatedKey = "MAP_PAGE.INFO_WINDOW_TITLE.NO_BLACKOUT";
            } else {
              key = "LOCATOR.SUPPLY_AREA_SUPPLIED_BY";
              translatedKey = "LOCATOR.NOT_IN_SUPPLY_AREA_TITLE";
            }
            break;
          default:
            throw new Error("Invalid Argument: " + this.supplyObject.supplyState);
        }

        if (this.supplyObject.supplyState === SupplyState.Disconnection && this.supplyObject.disconnectionStartTime?.length > 0 &&
            this.supplyObject.disconnectionEndTime?.length > 0) {
          const disconnectionStartTime: Moment = moment(this.supplyObject.disconnectionStartTime);
          const disconnectionEndTime: Moment = moment(this.supplyObject.disconnectionEndTime);
          const isNextDay: boolean = disconnectionEndTime.isAfter(disconnectionStartTime, "days");

          const rightFormat: string = isNextDay ? this.dateTimeFormat : this.onlyTimeFormat;

          const translation: string = this.bfcTranslationService.translate(key,
            { disconnectionStartTime: disconnectionStartTime.format(rightFormat),
              disconnectionEndTime: disconnectionEndTime.format(rightFormat) });
          this.translatedText = this.nvtReplaceCompanyNamePipe.transform(translation);

        } else if (!!key) {
          this.translatedText = this.nvtReplaceCompanyNamePipe.transform(this.bfcTranslationService.translate(key));
        }

        this.translatedTitle = this.nvtReplaceCompanyNamePipe.transform(
          this.bfcTranslationService.translate(translatedKey));
      }

      this.outageReportService.supplyObject.next(this.supplyObject);
    }
  }

  public isSuppliedOrBkwSupplier(): boolean {
    return this.supplyObject?.supplyState === SupplyState.Supplied ||
      this.supplyObject?.supplierName === this.bkwSupplierName;
  }

  public tel() {
    this.logService.log(LogEvent.StoerungsmeldungTelefon, this.supplyObject, null);
  }
}