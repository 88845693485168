import { SupplyState } from "./SupplyState";
import Polygon = google.maps.Polygon;

export class Area {
  private polygons: Polygon[];

  //TODO Refactor as this creates an array of googel's simple polygons instead of one google polygon with holes!
  constructor(private polygonPointsList: number[][][]) {
    this.polygons = <Polygon[]>polygonPointsList.map((polygonPoints) => {
      return new google.maps.Polygon({
        // switch from GeoJson's LngLat to Google's LatLng, polygonPoint[0] = Lng, polygonPoint[1] = Lat!
        paths: polygonPoints.map((polygonPoint) => new google.maps.LatLng(polygonPoint[1], polygonPoint[0])),
      });
    });
  }

  public changeStyle(supplyState: SupplyState, clickCount?: number): void {
    if (clickCount !== undefined && clickCount !== null && clickCount > 2) {
      let fillOpacity: number;
      if (clickCount < 6) {
        fillOpacity = 0.2;
      } else if (clickCount > 5 && clickCount < 8) {
        fillOpacity = 0.4;
      } else if (clickCount > 7) {
        fillOpacity = 0.6;
      }
      this.polygons.forEach((polygon) => {
        polygon.setOptions({
          fillColor: "#FF6418",
          fillOpacity: fillOpacity,
          paths: polygon.getPaths(),
          strokeColor: "#FF6418",
          strokeOpacity: 0.8,
          strokeWeight: 1,
        });
      });
    } else {
      switch (supplyState) {
        case SupplyState.Supplied:
          this.polygons.forEach((polygon) => {
            polygon.setOptions({
              fillColor: "#00925B",
              fillOpacity: 0.35,
              paths: polygon.getPaths(),
              strokeColor: "#00925B",
              strokeOpacity: 0.8,
              strokeWeight: 1,
            });
          });
          break;
        case SupplyState.Disconnection:
          this.polygons.forEach((polygon) => {
            polygon.setOptions({
              fillColor: "#0080C6",
              fillOpacity: 0.35,
              paths: polygon.getPaths(),
              strokeColor: "#0080C6",
              strokeOpacity: 0.8,
              strokeWeight: 1,
            });
          });
          break;
        case SupplyState.Failure:
          this.polygons.forEach((polygon) => {
            polygon.setOptions({
              fillColor: "#E30045",
              fillOpacity: 0.35,
              paths: polygon.getPaths(),
              strokeColor: "#E30045",
              strokeOpacity: 0.8,
              strokeWeight: 1,
            });
          });
          break;
        default:
        case null:
        case undefined:
          throw new Error("Invalid Argument: " + supplyState);
      }
    }
  }

  public setMap(map: any): void {
    this.polygons.forEach((polygon) => {
      polygon.setMap(map);
    });
  }

  public getPolygons(): Polygon[] {
    return this.polygons;
  }
}
