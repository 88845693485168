import { Component, HostBinding, OnInit, ViewEncapsulation } from "@angular/core";
import { SinglePageRouterService } from "../../services/single-page-router.service";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcTranslationService } from "@bfl/components/translation";
import { NvtReplaceCompanyNamePipe } from "../../../shared/pipes/nvt-replace-company-name.pipe";
import { Themes } from "../../common/Themes";

@Component({
  selector: "app-hints-page",
  templateUrl: "./hints-page.component.html",
  styleUrls: ["./hints-page.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HintsPageComponent implements OnInit {
  @HostBinding("class") classes = "hints-page";

  public theme: string;

  public isBKW: boolean;

  public isLagoule: boolean;

  public telNr: number;

  constructor(public singlePageRouterService: SinglePageRouterService,
    public bfcTranslationService: BfcTranslationService,
    private bfcConfigurationService: BfcConfigurationService,
    private nvtReplaceCompanyNamePipe: NvtReplaceCompanyNamePipe) {
  }

  ngOnInit(): void {
    this.theme = this.bfcConfigurationService.environment.theme;
    this.isBKW = this.bfcConfigurationService.environment.theme === Themes.BKW;
    this.isLagoule = this.bfcConfigurationService.environment.theme ===
        this.bfcConfigurationService.configuration.lagouleTheme;
    this.telNr = this.bfcConfigurationService.configuration.telNr;
  }

  content(): string {
    let content = this.nvtReplaceCompanyNamePipe.transform(
      this.bfcTranslationService.translate("HINTS_PAGE.CONTENT"));
    if (this.bfcConfigurationService.environment.theme === Themes.AEK ||
        this.bfcConfigurationService.environment.theme === Themes.ONYX)
      content = this.bfcTranslationService.translate("HINTS_PAGE.CONTENT_AEK_ONYX");

    return content;
  }

  numberText(): string {
    let key = "HINTS_PAGE.ACCORDION.NUMBER_TEXT";
    if (this.bfcConfigurationService.environment.theme === Themes.AEK) {
      key = key + "_AEK";
    }

    return this.bfcTranslationService.translate(key);
  }
}
