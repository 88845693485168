import { Injectable } from "@angular/core";
import { InfoWindowService } from "./info-window.service";
import { AreaService } from "./area.service";
import { SupplyObject } from "../common/SupplyObject";
import { SupplyState } from "../common/SupplyState";
import { Area } from "../common/Area";
import { AREA_ZOOM, SPECIFIC_ZOOM } from "../common/Const";
import { IMarkerClusterer } from "../common/IMarkerClusterer";
import { City } from "../common/City";
import { Trafo } from "../common/Trafo";
import { GoogleAnalyticsService } from "./google-analytics.service";
import { MapUtils } from "../common/MapUtils";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import {
    ISupplyZoneResponse,
    ISupplyZoneStateResponse,
    SupplyZoneRestApiService,
} from "./rest-api/supply-zone-rest-api.service";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { ITrafoPolygonResponse, ITrafoStateResponse, TrafoRestApiService, } from "./rest-api/trafo-rest-api.service";
import InfoWindow = google.maps.InfoWindow;
import Map = google.maps.Map;
import LatLng = google.maps.LatLng;

@Injectable()
export class SupplyObjectService {

    public supplyObjects: SupplyObject[] = [];

    private showAllPolygons: boolean;

    private count: number = 1;

    constructor(private supplyZoneRestApi: SupplyZoneRestApiService,
                private trafoRestApi: TrafoRestApiService,
                private infoWindowService: InfoWindowService,
                private googleAnalyticsService: GoogleAnalyticsService,
                private areaService: AreaService,
                private bfcConfigurationService: BfcConfigurationService) {
    }

    public loadSupplyObjects(
        parentMap: Map, markerClusterer: IMarkerClusterer, hasNVTRole: boolean,
        hasNVTLagouleRole: boolean, hasNVTInternalRole: boolean, showAllPolygons: boolean = false,
    ): Observable<any> {
        this.showAllPolygons = showAllPolygons;
        if (this.bfcConfigurationService.environment.theme === this.bfcConfigurationService.configuration.lagouleTheme) {
            if (hasNVTLagouleRole || hasNVTInternalRole) {
                return this.supplyZoneRestApi.getSupplyZoneStatesAndClickCounts().pipe(
                    switchMap((supplyZoneStateResponses: ISupplyZoneStateResponse[]) => {
                        this.supplyObjects = supplyZoneStateResponses.map(
                            (supplyZoneStateResponse: ISupplyZoneStateResponse) => {
                                return this.createCity(
                                    supplyZoneStateResponse.supplyZoneId,
                                    supplyZoneStateResponse.plz,
                                    supplyZoneStateResponse.city,
                                    supplyZoneStateResponse.supplyState,
                                    supplyZoneStateResponse.latitude,
                                    supplyZoneStateResponse.longitude,
                                    parentMap,
                                    markerClusterer,
                                    showAllPolygons,
                                    supplyZoneStateResponse.clickCount,
                                );
                            });
                        return of(undefined);
                    }),
                );
            } else {
                return this.supplyZoneRestApi.getSupplyZoneStates().pipe(
                    switchMap((supplyZoneStateResponses: ISupplyZoneStateResponse[]) => {
                        this.supplyObjects = supplyZoneStateResponses.map(
                            (supplyZoneStateResponse: ISupplyZoneStateResponse) => {
                                return this.createCity(
                                    supplyZoneStateResponse.supplyZoneId,
                                    supplyZoneStateResponse.plz,
                                    supplyZoneStateResponse.city,
                                    supplyZoneStateResponse.supplyState,
                                    supplyZoneStateResponse.latitude,
                                    supplyZoneStateResponse.longitude,
                                    parentMap,
                                    markerClusterer,
                                    showAllPolygons,
                                );
                            });
                        return of(undefined);
                    }),
                );
            }
        } else {
            if (hasNVTRole || hasNVTInternalRole) {
                return this.trafoRestApi.getTrafoStatesAndClickCounts().pipe(
                    switchMap((trafoStateResponses: ITrafoStateResponse[]) => {
                        this.supplyObjects = trafoStateResponses.map(
                            (trafoStateResponse: ITrafoStateResponse) => {
                                return this.createTrafo(
                                    trafoStateResponse.trafoNumber,
                                    trafoStateResponse.supplyState,
                                    trafoStateResponse.latitude,
                                    trafoStateResponse.longitude,
                                    parentMap,
                                    markerClusterer,
                                    trafoStateResponse.disconnectionStartTime,
                                    trafoStateResponse.disconnectionEndTime,
                                    showAllPolygons,
                                    null,
                                    null,
                                    trafoStateResponse.clickCount,
                                );
                            });
                        return of(undefined);
                    }),
                );
            } else {
                return this.trafoRestApi.getTrafoStates().pipe(
                    switchMap((trafoStateResponses: ITrafoStateResponse[]) => {
                        this.supplyObjects = trafoStateResponses.map(
                            (trafoStateResponse: ITrafoStateResponse) => {
                                return this.createTrafo(
                                    trafoStateResponse.trafoNumber,
                                    trafoStateResponse.supplyState,
                                    trafoStateResponse.latitude,
                                    trafoStateResponse.longitude,
                                    parentMap,
                                    markerClusterer,
                                    trafoStateResponse.disconnectionStartTime,
                                    trafoStateResponse.disconnectionEndTime,
                                    showAllPolygons,
                                );
                            });
                        return of(undefined);
                    }),
                );
            }
        }
    }

    public reloadSupplyObjects(hasNVTRole: boolean,
                               hasNVTLagouleRole: boolean,
                               hasNVTInternalRole: boolean,
                               showAllPolygons: boolean = false): Observable<any> {

        this.showAllPolygons = showAllPolygons;
        if (this.bfcConfigurationService.environment.theme === this.bfcConfigurationService.configuration.lagouleTheme) {
            if (hasNVTLagouleRole || hasNVTInternalRole) {
                return this.supplyZoneRestApi.getSupplyZoneStatesAndClickCounts().pipe(
                    switchMap((supplyZoneStateResponses: ISupplyZoneStateResponse[]) => {
                        supplyZoneStateResponses.forEach(
                            (supplyZoneStateResponse: ISupplyZoneStateResponse) => {
                                const supplyObject: SupplyObject = this.supplyObjects.find(
                                    (object: SupplyObject) => object.id === supplyZoneStateResponse.supplyZoneId,
                                );
                                this.applySupplyStateAndClicks(
                                    supplyObject,
                                    supplyZoneStateResponse.supplyState,
                                    showAllPolygons,
                                    supplyZoneStateResponse.clickCount);
                            });
                        return of(undefined);
                    }),
                );
            } else {
                return this.supplyZoneRestApi.getSupplyZoneStates().pipe(
                    switchMap((supplyZoneStateResponses: ISupplyZoneStateResponse[]) => {
                        supplyZoneStateResponses.forEach(
                            (supplyZoneStateResponse: ISupplyZoneStateResponse) => {
                                const supplyObject: SupplyObject = this.supplyObjects.find(
                                    (object: SupplyObject) => object.id === supplyZoneStateResponse.supplyZoneId,
                                );
                                this.applySupplyStateAndClicks(supplyObject, supplyZoneStateResponse.supplyState, showAllPolygons);
                            });
                        return of(undefined);
                    }),
                );
            }
        } else {
            if (hasNVTRole || hasNVTInternalRole) {
                return this.trafoRestApi.getTrafoStatesAndClickCounts().pipe(
                    switchMap((trafoStateResponses: ITrafoStateResponse[]) => {
                        trafoStateResponses.forEach(
                            (trafoStateResponse: ITrafoStateResponse) => {
                                const supplyObject: SupplyObject = this.supplyObjects.find(
                                    (object: SupplyObject) => object.id === trafoStateResponse.trafoNumber,
                                );
                                this.applySupplyStateAndClicks(
                                    supplyObject, trafoStateResponse.supplyState, showAllPolygons, trafoStateResponse.clickCount);
                            });
                        return of(undefined);
                    }),
                );
            } else {
                return this.trafoRestApi.getTrafoStates().pipe(
                    switchMap((trafoStateResponses: ITrafoStateResponse[]) => {
                        trafoStateResponses.forEach(
                            (trafoStateResponse: ITrafoStateResponse) => {
                                const supplyObject: SupplyObject = this.supplyObjects.find(
                                    (object: SupplyObject) => object.id === trafoStateResponse.trafoNumber,
                                );
                                this.applySupplyStateAndClicks(supplyObject, trafoStateResponse.supplyState, showAllPolygons);
                            });
                        return of(undefined);
                    }),
                );
            }
        }
    }

    public createCity(id: number, zip: number, city: string, supplyState: SupplyState, lat: number, lng: number,
                      googleMap: Map, markerClusterer: IMarkerClusterer, showAllPolygons: boolean = false, clickCount?: number): City {
        let supplyObject: City = new City(id, zip, city, googleMap, markerClusterer);
        return this.createSupplyObject(supplyObject, id, supplyState, lat, lng, googleMap, showAllPolygons, null, null, clickCount);
    }

    public createTrafo(id: number,
                       supplyState: SupplyState,
                       lat: number,
                       lng: number,
                       googleMap: Map,
                       markerClusterer: IMarkerClusterer,
                       disconnectionStartTime: string,
                       disconnectionEndTime: string,
                       showAllPolygons: boolean = false,
                       supplierName?: string,
                       supplierUrl?: string,
                       clickCount?: number): Trafo {

        let supplyObject: Trafo = new Trafo(id, googleMap, markerClusterer, disconnectionStartTime, disconnectionEndTime);
        return this.createSupplyObject(supplyObject, id, supplyState, lat, lng, googleMap, showAllPolygons, supplierName, supplierUrl, clickCount);
    }

    private createSupplyObject<T extends SupplyObject>(
        supplyObject: T, id: number, supplyState: SupplyState, lat: number, lng: number, googleMap: Map,
        showAllPolygons: boolean, supplierName?: string, supplierUrl?: string, clickCount?: number): T {
        this.showAllPolygons = showAllPolygons;

        let marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
        });
        marker.addListener("click", () => {
            this.googleAnalyticsService.logSupplyObjectClicked(supplyObject);
            this.focus(supplyObject);
        });
        supplyObject.setMarker(marker);

        supplyObject.supplierName = supplierName;
        supplyObject.supplierUrl = supplierUrl;

        // outside of the supply area
        if (id === null) {
            const infoWindow: InfoWindow = this.infoWindowService.createInfoWindow(id);
            supplyObject.setInfoWindow(infoWindow);
        }

        this.applySupplyStateAndClicks(supplyObject, supplyState, showAllPolygons, clickCount);

        google.maps.event.addListener(googleMap, "zoom_changed", () => {
            if (supplyObject.visible) {
                if (this.areaService.hasArea(supplyObject)) {
                    if (this.count !== 1) {
                        // reseting the count
                        this.count = 1;
                    }
                    this.areaService.getArea(supplyObject).subscribe((area: Area) => {
                        if (area) {
                            area.setMap(googleMap.getZoom() >= AREA_ZOOM ? googleMap : undefined);
                        }
                    });
                } else {
                    if (!!this.showAllPolygons) {
                        // add to count (timeout) to not run into not enough resources
                        this.count += 8;
                    } else {
                        // reseting the count
                        this.count = 1;
                    }
                    setTimeout(() => {
                        this.areaService.getArea(supplyObject).subscribe((area: Area) => {
                            if (area) {
                                area.setMap(googleMap.getZoom() >= AREA_ZOOM ? googleMap : undefined);
                            }
                        });
                    }, !!this.showAllPolygons ? this.count : 0);
                }
            } else {
                if (this.areaService.hasArea(supplyObject)) {
                    this.areaService.getArea(supplyObject).subscribe((area: Area) => {
                        if (area) {
                            area.setMap(undefined);
                        }
                    });
                }
            }
        }); 

        return supplyObject;
    }

    public applySupplyStateAndClicks(supplyObject: SupplyObject,
                                     supplyState: SupplyState,
                                     showAllPolygons: boolean,
                                     clickCount?: number): void {

        this.showAllPolygons = showAllPolygons;

        const oldSupplyState: SupplyState = supplyObject.supplyState;

        if (oldSupplyState === supplyState &&
            !supplyObject.hasEnoughClicks() &&
            (clickCount === undefined || clickCount === null)
        ) {
            return;
        }

        if (clickCount !== undefined && clickCount !== null) {
            supplyObject.clickCount = clickCount;
        }

        supplyObject.supplyState = supplyState;
        supplyObject.applySupplyStateAndClicksStyle(!!showAllPolygons);

        if (this.areaService.hasArea(supplyObject)) {
            this.areaService.getArea(supplyObject).subscribe((area: Area) => {
                if (area) {
                    area.changeStyle(supplyState, supplyObject.clickCount);
                }
            });
        }

        if (supplyObject.visible && !showAllPolygons) {
            if (supplyState === SupplyState.Supplied && !supplyObject.hasEnoughClicks()) {
                // if a blackout supplyObject turns no blackout only keep it on the map if the info window is opened
                if (supplyObject.infoWindowIsOpen()) {
                    supplyObject.getMarkerClusterer().removeMarker(supplyObject.getMarker());
                    supplyObject.getMarker()?.setMap(supplyObject?.getMap());
                } else {
                    supplyObject.getMarkerClusterer().removeMarker(supplyObject.getMarker());
                    supplyObject.visible = false;

                    if (this.areaService.hasArea(supplyObject)) {
                        this.areaService.getArea(supplyObject).subscribe((area: Area) => {
                            if (area) {
                                area.setMap(undefined);
                            }
                        });
                    }
                }
            } else if (oldSupplyState === SupplyState.Supplied && !supplyObject.hasEnoughClicks()) {
                supplyObject.getMarker()?.setMap(undefined);
                supplyObject.getMarkerClusterer().addMarker(supplyObject.getMarker());
            }
        } else if ((supplyState !== SupplyState.Supplied || supplyObject.hasEnoughClicks()) || !!showAllPolygons) {
            // always show blackout and warning markers and if showAllPolygons is set
            this.applyVisibility(supplyObject, true, showAllPolygons);
        }
    }

    public applyVisibility(supplyObject: SupplyObject, visible: boolean, showAllPolygons: boolean): void {
        if (supplyObject.visible === visible) {
            return;
        }

        supplyObject.visible = visible;
        if (visible) {

            if (!showAllPolygons && !supplyObject.getMarker()?.getVisible()) {
                supplyObject.getMarker().setVisible(true);
            }

            if (supplyObject.isSuppliedOrOutside()) {
                supplyObject.getMarker()?.setMap(supplyObject?.getMap());
            } else if (supplyObject.getMarkerClusterer() !== null && supplyObject.getMarker()) {
                supplyObject.getMarkerClusterer().addMarker(supplyObject.getMarker());
            }

            if (supplyObject?.getMap().getZoom() >= AREA_ZOOM) {
                this.areaService.getArea(supplyObject).subscribe((area: Area) => {
                    if (area) {
                        area.setMap(supplyObject?.getMap());
                    }
                });
            }
        } else {
            if (supplyObject.isSuppliedOrOutside()) {
                supplyObject.getMarker()?.setMap(undefined);
            } else if (supplyObject.getMarkerClusterer() !== null) {
                supplyObject.getMarkerClusterer().removeMarker(supplyObject.getMarker());
            }

            if (this.areaService.hasArea(supplyObject)) {
                this.areaService.getArea(supplyObject).subscribe((area: Area) => {
                    if (area) {
                        area.setMap(undefined);
                    }
                });
            }
        }
    }

    public focus(supplyObject: SupplyObject): void {
        // remove all other supplied objects from the map
        this.supplyObjects.forEach((object: SupplyObject) => {
            if (object.isSuppliedOrOutside() && !object.hasEnoughClicks() && !this.showAllPolygons) {
                this.applyVisibility(object, false, this.showAllPolygons);
            }
        });
        // add this supply object to the map if not already
        if (!supplyObject?.visible) {
            this.applyVisibility(supplyObject, true, this.showAllPolygons);
        }

        if (this.bfcConfigurationService.environment.theme === this.bfcConfigurationService.configuration.lagouleTheme) {
            supplyObject?.getMap().setZoom(AREA_ZOOM);
        } else if (supplyObject.getMarker() && supplyObject?.getMap().getZoom() < SPECIFIC_ZOOM) {
            supplyObject?.getMap().setZoom(SPECIFIC_ZOOM);
        }

        MapUtils.panToWithOffset(supplyObject?.getMap(), supplyObject.getMarker().getPosition(), 0, 100);

        this.infoWindowService.closeAllInfoWindows();

        if (supplyObject.getInfoWindow()) {
            this.infoWindowService.openInfoWindow(supplyObject);
        } else {
            const infoWindow: InfoWindow = this.infoWindowService.createInfoWindow(supplyObject.id);
            supplyObject.setInfoWindow(infoWindow);
            // wait for the creation of the info window
            setTimeout(() => this.infoWindowService.openInfoWindow(supplyObject), 300);
        }

    }

    public hasBlackouts(): boolean {
        return this.supplyObjects.some(
            (supplyObject: SupplyObject) => supplyObject.id && supplyObject.supplyState !== SupplyState.Supplied,
        );
    }

    public getSupplyObjectByCoordinates(latLng: LatLng, gMap?: Map, markerClusterer?: IMarkerClusterer): Observable<SupplyObject> {
        if (this.bfcConfigurationService.environment.theme === this.bfcConfigurationService.configuration.lagouleTheme) {
            return this.supplyZoneRestApi.getSupplyZoneByCoordinates(latLng).pipe(
                map((supplyZoneResponse: ISupplyZoneResponse) => {
                    if (supplyZoneResponse) {
                        return this.getSupplyObjectById(supplyZoneResponse.supplyZoneId);
                    } else {
                        return null;
                    }
                }),
            );
        } else {
            return this.trafoRestApi.getTrafoByCoordinates(latLng).pipe(
                map((trafoPolygonResponse: ITrafoPolygonResponse) => {
                    if (trafoPolygonResponse) {
                        let supplyObject = this.getSupplyObjectById(trafoPolygonResponse.trafoNumber);
                        if (!supplyObject) {
                            supplyObject = this.createTrafo(
                                trafoPolygonResponse.trafoNumber,
                                SupplyState.Supplied,
                                latLng.lat(),
                                latLng.lng(),
                                gMap,
                                markerClusterer,
                                null,
                                null,
                                this.showAllPolygons
                            );
                            this.supplyObjects.push(supplyObject);
                        }
                        
                        return supplyObject;
                    }
                    return null;
                }),
            );
        }
    }

    public getSupplyObjectById(id: number): SupplyObject {
        return this.supplyObjects.find((supplyObject: SupplyObject) => {
            return supplyObject.id === id;
        });
    }

}
