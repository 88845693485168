export const T_ENV = {
  // BKW
  contactUrls: {
    de: "https://connect-t.int.bkw.ch/contact?from=https://outage-t.int.bkw.ch",
    fr: "https://connect-t.int.bkw.ch/contact?from=https://outage-t.int.bkw.ch",
  },

  // common 
  nvtBackendUrl: "https://api-outage-t.bkw.ch/api",
  opSelfServiceApiUrl: "https://api-op-self-service-t.bkw.ch",
};