export const MIN_ZOOM_MOBILE: number = 7;
export const MIN_ZOOM: number = 8;
export const AREA_ZOOM: number = 12;
export const SPECIFIC_ZOOM: number = 15;
export const MAX_ZOOM: number = 18;

export const SMALL_BLUE_BUBBLE_STYLE_INDEX: number = 1;
export const SMALL_RED_BUBBLE_STYLE_INDEX: number = 2;
export const LARGE_BLUE_BUBBLE_STYLE_INDEX: number = 3;
export const LARGE_RED_BUBBLE_STYLE_INDEX: number = 4;

export const MARKER_KEY_SUPPLY_STATE: string = "supplyState";
