import { Component } from "@angular/core";
import { BfcTranslationService } from "@bfl/components/translation";
import { BfcNotification, BfcNotificationType } from "@bfl/components/notification";

@Component({
  selector: "app-disclaimer",
  templateUrl: "./disclaimer.component.html",
})
export class DisclaimerComponent {
  disclaimerNotification: BfcNotification = {
    type: BfcNotificationType.WARNING,
    message: this.bfcTranslationService.translate("DISCLAIMER"),
    id: "disclaimer-notification",
    options: {
      duration: 0,
    },
  };

  constructor(private bfcTranslationService: BfcTranslationService) {
  }

}
