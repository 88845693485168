import { Component } from "@angular/core";
import { SinglePageRouterService } from "../../services/single-page-router.service";

@Component({
  selector: "app-outage-report-confirmation-page",
  templateUrl: "./outage-report-confirmation-page.component.html",
  styleUrls: ["./outage-report-confirmation-page.component.scss"],
})
export class OutageReportConfirmationPageComponent {

  constructor(public singlePageRouterService: SinglePageRouterService) {
  }

}
