import { SupplyObject } from "./SupplyObject";

export class Trafo extends SupplyObject {

  public getDisplayText(): string {
    if (this.id) {
      return "Trafostations-Nr. " + this.id;
    }
    return "";
  }
}
