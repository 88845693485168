import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from "@angular/core";
import { MapComponent } from "../map/map.component";
import { GoogleAnalyticsService } from "../../services/google-analytics.service";
import { GlobalMessageService } from "../../services/global-message.service";
import { SinglePageRouterService } from "../../services/single-page-router.service";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { BfcTranslationService } from "@bfl/components/translation";
import {
    AREA_ZOOM,
    LARGE_BLUE_BUBBLE_STYLE_INDEX,
    LARGE_RED_BUBBLE_STYLE_INDEX,
    MARKER_KEY_SUPPLY_STATE,
    SMALL_BLUE_BUBBLE_STYLE_INDEX,
    SMALL_RED_BUBBLE_STYLE_INDEX,
} from "../../common/Const";
import { mergeMap, take, takeUntil } from "rxjs/operators";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { LogService } from "../../services/log.service";
import { LogEvent } from "../../common/LogEvent";
import { InfoWindowService } from "../../services/info-window.service";
import { IPublicGlobalMessageResponse } from "../../services/rest-api/global-message-rest-api.service";
import { SupplyObjectService } from "../../services/supply-object.service";
import { SupplyObject } from "../../common/SupplyObject";

import * as MarkerClusterer from "@google/markerclusterer";
import { SupplyState } from "../../common/SupplyState";
import { BfcNotification, BfcNotificationType } from "@bfl/components/notification";
import { Observable, Subject } from "rxjs";
import { City } from "../../common/City";
import { SupplierDto } from "../../common/SupplierDto";
import { SupplierRestApiService } from "../../services/rest-api/supplier-rest-api.service";
import { MultiPolygon } from "../../common/MultiPolygon";
import { PolygonService } from "../../services/polygon.service";
import Map = google.maps.Map;
import LatLng = google.maps.LatLng;
import MouseEvent = google.maps.MouseEvent;
import Polygon = google.maps.Polygon;

@Component({
    selector: "app-map-page",
    templateUrl: "map-page.component.html",
    styleUrls: ["map-page.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class MapPageComponent implements AfterViewInit, OnDestroy {
    private static isClusterClicked = false;

    @HostBinding("class")
    classes = "map-page";

    @ViewChild("map")
    public mapComponent: MapComponent;

    public isLagoule: boolean;

    public isGeolocationSupported: boolean = navigator.geolocation !== undefined;

    public showGlobalMessageLink: boolean;

    public hasAnyValidNVTRole: boolean;

    public globalMessage: string;

    public showLoadingIndicator: boolean = true;

    public noBlackout: boolean;

    public globalNotification: Observable<BfcNotification>;

    blackoutNotification: BfcNotification = {
        type: BfcNotificationType.SUCCESS,
        message: this.bfcTranslationService.translate("MAP_PAGE.NO_BLACKOUTS_MESSAGE"),
        id: "blackout-notification",
        options: {
            actions: {
                secondary: {
                    name: this.bfcConfigurationService.configuration.telNr,
                    callback: () => {
                        window.open(`tel:` + this.bfcConfigurationService.configuration.telNr, "_self");
                    },
                    closeAfterCallback: false,
                },
                primary: {
                    name: this.bfcTranslationService.translate("MAP_PAGE.OUTAGE_REPORT"),
                    callback: () => {
                        this.singlePageRouterService.state.next("outage-report");
                    },
                    closeAfterCallback: false,
                },
            },
            duration: 0,
        },
    };

    @ViewChild("hintsLinkMobile")
    public hintsLinkMobile: ElementRef;

    private markerClusterer: MarkerClusterer;

    private masterClustererLibIsOverwritten: boolean = false;

    private geoLocation: LatLng;

    private hasNVTRole: boolean = false;

    private hasNVTLagouleRole: boolean = false;

    private hasNVTInternalRole: boolean = false;

    private showAllPolygons: boolean = false;

    private locationIcon: HTMLElement;

    private unsubscribe: Subject<void> = new Subject<void>();

    private currentSupplierMultiPolygon: MultiPolygon;

    constructor(private viewContainerRef: ViewContainerRef,
                private googleAnalyticsService: GoogleAnalyticsService,
                private globalMessageService: GlobalMessageService,
                private infoWindowService: InfoWindowService,
                private logService: LogService,
                private bfcAuthenticationService: BfcAuthenticationService,
                private bfcTranslationService: BfcTranslationService,
                private supplierService: SupplierRestApiService,
                public bfcConfigurationService: BfcConfigurationService,
                public singlePageRouterService: SinglePageRouterService,
                public supplyObjectService: SupplyObjectService,
                public polygonService: PolygonService) {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(() => {
            }, () => {
                this.isGeolocationSupported = false;
            });
        }

        this.globalMessageService.publicGlobalMessage.pipe(takeUntil(this.unsubscribe)).subscribe(
            (publicGlobalMessage: IPublicGlobalMessageResponse) => {
                if (!publicGlobalMessage) {
                    return;
                }

                if (this.globalMessageService.showGlobalMessage) {
                    this.globalMessage = this.bfcTranslationService.language !== "fr" ? publicGlobalMessage.messageDe : publicGlobalMessage.messageFr;

                    this.globalMessageService.setNotification({
                        type: BfcNotificationType.WARNING,
                        message: this.globalMessage,
                        id: "global-notification",
                    });

                    this.getNotification();
                }
            });
    }

    private get map(): Map {
        return this.mapComponent.map;
    }

    ngAfterViewInit(): void {
        this.addClickListener();
        this.initMarkerClusterer().then(() => {
            this.hasNVTRole = this.bfcAuthenticationService.hasRealmRole(this.bfcConfigurationService.configuration.roleNvt);
            this.hasNVTLagouleRole = this.bfcAuthenticationService.hasRealmRole(
                this.bfcConfigurationService.configuration.roleNvtLagoule);
            this.hasNVTInternalRole = this.bfcAuthenticationService.hasRealmRole(
                this.bfcConfigurationService.configuration.roleNvtInternal);
            this.isLagoule = (this.bfcConfigurationService.environment.theme ==
                this.bfcConfigurationService.configuration.lagouleTheme);   // theme lagoule

            this.showGlobalMessageLink =
                // ROLE_NVT and not theme lagoule (theme aek, bkw or onyx)
                (this.hasNVTRole && !this.isLagoule)
                // or ROLE_NVT_LAGOULE and theme lagoule
                || (this.hasNVTLagouleRole && this.isLagoule);

            this.hasAnyValidNVTRole =
                // ROLE_NVT or ROLE_NVT_INTERNAL and not theme lagoule (theme bkw)
                (!this.isLagoule && (this.hasNVTRole || this.hasNVTInternalRole))
                // or ROLE_NVT_LAGOULE or ROLE_NVT_INTERNAL and theme lagoule
                || (this.isLagoule && (this.hasNVTLagouleRole || this.hasNVTInternalRole));

            this.loadSupplyObjects();
        });
        this.infoWindowService.setRootViewContainerRef(this.viewContainerRef);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    /* called from geolocation usage in map-page component template */
    public requestGeoLocation() {
        navigator.geolocation.getCurrentPosition(position => {
            this.locateLatLng(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
        }, e => {
            // eslint-disable-next-line no-console
            console.error("HTML5 Geolocation retrieval failed", e);
            this.isGeolocationSupported = false;
        });
    }

    /* called from google-locator usage in map-page component template */
    public locateLatLng(latLng: LatLng): void {
        this.geoLocation = latLng;
        if (this.polygonService.pointIsInsideMultiPolygon(latLng, this.currentSupplierMultiPolygon)) {
            this.supplyObjectService.getSupplyObjectByCoordinates(this.geoLocation, this.map, this.markerClusterer).subscribe(
                (supplyObject: SupplyObject) => {
                    this.locateSupplyObject(supplyObject);  // show InfoWindow and draw TrafoPolygon from SupplyObject
                });
        } else {
            this.supplierService.getSupplierByCoordinates(latLng).subscribe((supplier: SupplierDto[]) => {
                this.locateSupplierDto(supplier[0]); // show InfoWindow from foreign SupplierDto
            });
        }
    }

    /* called from locator usage in map-page component template */
    public locateSupplyObject(supplyObject: SupplyObject): void {
        if (supplyObject) {
            this.logService.log(LogEvent.Abfrage, supplyObject, this.geoLocation);
            this.googleAnalyticsService.logSupplyObjectLocated(supplyObject);
            this.supplyObjectService.focus(supplyObject);
        } else {
            const supplyObject = this.supplyObjectService.createTrafo(null, SupplyState.Supplied, this.geoLocation.lat(), this.geoLocation.lng(), this.map, null, null, null, null, null, null, null);
            this.supplyObjectService.supplyObjects.push(supplyObject);
            // wait for the trafo to be created
            //setTimeout(() => this.supplyObjectService.focus(supplyObject), 200);
            this.supplyObjectService.focus(supplyObject);
        }
    }

    public locateSupplierDto(supplier: SupplierDto): void {
        const supplyObject = this.supplyObjectService.createTrafo(null, null, this.geoLocation.lat(), this.geoLocation.lng(), this.map, null, null, null, null, supplier?.name, supplier?.url, null);
        this.supplyObjectService.supplyObjects.push(supplyObject);
        // wait for the trafo to be created
        //setTimeout(() => this.supplyObjectService.focus(supplyObject), 200);
        this.supplyObjectService.focus(supplyObject);
    }

    public openContactLink(): void {
        if (this.bfcTranslationService.language == "de") {
            window.open("https://www.bkw.ch/de/kontakt#c4655", "_blank");
        } else {
            window.open("https://www.bkw.ch/fr/contact#c4655", "_blank");
        }
    }

    public scrollMobile() {
        this.hintsLinkMobile.nativeElement.scrollIntoView({behavior: "smooth", block: "end"});
    }

    public setShowAllPolygons() {
        this.showAllPolygons = !this.showAllPolygons;
        this.reloadSupplyObjects();
    }

    getCities(supplyObjects: SupplyObject[]): City[] {
        return supplyObjects as City[];
    }

    private getNotification() {
        this.globalMessageService.getNotification().subscribe(notification => {
            this.globalNotification = notification;
        });
    }

    private addClickListener(): void {
        this.map.addListener("click", (e: MouseEvent) => {
            const that = this;

            // Timeout to make sure that the Cluster click event can set the Flag
            setTimeout(function () {
                if (!MapPageComponent.isClusterClicked) {
                    that.locateLatLng(e.latLng);
                } else {
                    MapPageComponent.isClusterClicked = false;
                }
            }, 0);

        });
    }

    private async initMarkerClusterer() {
        this.locationIcon = document.getElementById("location");
        this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(this.locationIcon);
        this.markerClusterer = new MarkerClusterer(
            this.map,
            [],
            {
                maxZoom: AREA_ZOOM - 1, // do not show clusters on most detailed zoom level
                styles: [{
                    height: 58,
                    textColor: "transparent",
                    url: "assets/img/BKW_Icon_Sml_Bubble_Blue.svg",
                    width: 58,
                }, {
                    height: 58,
                    textColor: "transparent",
                    url: "assets/img/BKW_Icon_Sml_Bubble_Red.svg",
                    width: 58,
                }, {
                    height: 78,
                    textColor: "transparent",
                    url: "assets/img/BKW_Icon_Lrg_Bubble_Blue.svg",
                    width: 78,
                }, {
                    height: 78,
                    textColor: "transparent",
                    url: "assets/img/BKW_Icon_Lrg_Bubble_Red.svg",
                    width: 78,
                }],
            });

        const mapMarkers = [
            new google.maps.Marker({map: this.map, position: new google.maps.LatLng(47.001, 7.001)}),
            new google.maps.Marker({map: this.map, position: new google.maps.LatLng(47.002, 7.002)}),
            new google.maps.Marker({map: this.map, position: new google.maps.LatLng(47.003, 7.003)}),
            new google.maps.Marker({map: this.map, position: new google.maps.LatLng(47.004, 7.004)}),
        ];
        this.markerClusterer.addMarkers(mapMarkers);
        this.markerClusterer.setCalculator((markers: google.maps.Marker[]) => {
            let index;

            const markersFailure = markers.filter(marker => marker.get(MARKER_KEY_SUPPLY_STATE) === SupplyState.Failure);

            if (markers.length > 9) {
                index = markersFailure.length > 0 ? LARGE_RED_BUBBLE_STYLE_INDEX : LARGE_BLUE_BUBBLE_STYLE_INDEX;
            } else {
                index = markersFailure.length > 0 ? SMALL_RED_BUBBLE_STYLE_INDEX : SMALL_BLUE_BUBBLE_STYLE_INDEX;
            }

            return {
                text: "",
                index: index,
            };
        });
        await new Promise(resolve => setTimeout(resolve, 3500));    // wait a moment for the markers to be added!
        this.overwriteClusterIconPrototypeOnAdd();
        this.markerClusterer.clearMarkers();
    }

    private loadSupplyObjects(): void {

        if (!this.isLagoule) {
            this.drawBkwSupplierPolygons();
        }

        this.supplyObjectService.loadSupplyObjects(
            this.map, this.markerClusterer, this.hasNVTRole, this.hasNVTLagouleRole, this.hasNVTInternalRole).pipe(
                mergeMap(() => {
                    this.showLoadingIndicator = false;

                    if (this.isGeolocationSupported) {
                        this.requestGeoLocation();
                    }

                    return this.globalMessageService.loadPublicGlobalMessage();
                }),
            ).subscribe(() => {
            this.noBlackout = !this.globalMessage && !this.supplyObjectService.hasBlackouts();
            setInterval(() => {}, 100);
            setInterval(() => this.googleAnalyticsService.logMapType(this.map), 15000);
            setInterval(() => this.reloadSupplyObjects(), 60000);
        });
    }

    private reloadSupplyObjects(): void {
        this.supplyObjectService.reloadSupplyObjects(this.hasNVTRole,
            this.hasNVTLagouleRole,
            this.hasNVTInternalRole,
            this.showAllPolygons)
            .pipe(
                take(1),
                mergeMap(() => {
                    return this.globalMessageService.loadPublicGlobalMessage();
                }),
            ).subscribe(() => {
            this.noBlackout = !this.globalMessage && !this.supplyObjectService.hasBlackouts();
        });
    }

    private drawBkwSupplierPolygons(): void {
        const polygons: Polygon[] = [];

        this.supplierService.getSupplier().subscribe((supplier: SupplierDto[]) => {

            // remember and sort the multi polygon from backend, later we check if coordinates clicked are inside it.
            this.currentSupplierMultiPolygon = supplier[0].coverageArea;
            this.currentSupplierMultiPolygon.coordinates.sort((p1,p2) => p2.length - p1.length);

            supplier[0].coverageArea.coordinates.forEach((polygon: number[][][]) => {

                polygon.forEach((areaOrHole: number[][], index: number) => {
                    const latLngs: LatLng[] = [];

                    areaOrHole.forEach((point: number[]) => {
                        const latLng: LatLng = new google.maps.LatLng(point[1], point[0]);
                        latLngs.push(latLng);
                    });

                    if (index === 0) {
                        const polygon: Polygon = new google.maps.Polygon({
                            paths: latLngs,
                            fillColor: "#D6D700",
                            fillOpacity: 0.5,
                            strokeColor: "#D6D700",
                            strokeOpacity: 0.7,
                            strokeWeight: 1,
                            clickable: false,
                            map: this.map,
                        });

                        polygons.push(polygon);
                    } else {
                        const polygon: Polygon = polygons[polygons.length - 1];
                        // add the hole
                        polygon.getPaths().insertAt(index, new google.maps.MVCArray(latLngs));
                    }
                });
            });
        });
    }

    /**
     * Overwrite the ClusterIcon onAdd function to stop onclick event propagation to the google map.
     * The body of the onAdd function ist taken from line 1077 from source
     * /node_modules/@google/markerclusterer/src/markerclusterer.js
     * This must be done once after the cluster is instantiated for the first time.
     */
    private overwriteClusterIconPrototypeOnAdd(): void {
        if (!this.masterClustererLibIsOverwritten && this.markerClusterer.getTotalClusters() > 0) {
            Object.getPrototypeOf(this.markerClusterer.clusters_[0].clusterIcon_).onAdd = function () {
                this.div_ = document.createElement("DIV");
                if (this.visible_) {
                    const pos = this.getPosFromLatLng_(this.center_);
                    this.div_.style.cssText = this.createCss(pos);
                    this.div_.innerHTML = this.sums_.text;
                }

                const panes = this.getPanes();
                panes.overlayMouseTarget.appendChild(this.div_);

                const that = this;
                google.maps.event.addDomListener(this.div_, "click", function () {
                    MapPageComponent.isClusterClicked = true;
                    that.triggerClusterClick();
                    event.cancelBubble = true;
                    event.stopPropagation();

                    // check if click event hasn't been propagated to map
                    // and accordingly reset here MapPageComponent.isClusterClicked
                    setTimeout(function () {
                        if (MapPageComponent.isClusterClicked) {
                            MapPageComponent.isClusterClicked = false;
                        }
                    }, 100);

                });
            };
            this.markerClusterer.redraw();
            this.masterClustererLibIsOverwritten = true;
        }
    }

}
