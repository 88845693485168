import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { ThemeNameTransformService } from "../theme-name-transform.service";

@Injectable()
export class OutageReportRestApiService {
  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    private realmNameTransformService: ThemeNameTransformService) {
  }

  public sendOutageReport(outageReportData: IOutageReportData): Observable<any> {
    const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
    return this.httpClient.post(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/outageReport", outageReportData, { params });
  }

  public sendOutageReportMultipartForm(outageReportMultipartFormData): Observable<any> {
    const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
    return this.httpClient.post(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/outageReport", outageReportMultipartFormData, { params });
  }
}

export interface IOutageReportData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  streetAndHouseNo: string;
  zipAndCity: string;
  coordinates: string;
  trafoStation: number;
  message: string;
  language: string;
}

export interface IOutageReportMultipartFormData {
  outageReport: Blob,
  fileName: string,
  file: Blob
}
