export const Q_ENV = {
  // BKW
  contactUrls: {
    de: "https://connect-q.bkw.ch/contact?from=https://outage-q.bkw.ch",
    fr: "https://connect-q.bkw.ch/contact?from=https://outage-q.bkw.ch",
  },

  // common 
  nvtBackendUrl: "https://api-outage-q.bkw.ch/api",
  opSelfServiceApiUrl: "https://api-op-self-service-q.bkw.ch",
};