<bfc-base-page-layout>

    <div class="back-button-default">
        <a (click)="singlePageRouterService.state.next('map')"><span>« {{ 'OUTAGE_REPORT_PAGE.BACK_LINK' | bfcTranslate }}</span></a>
    </div>

    <h1>{{ 'OUTAGE_REPORT_PAGE.TITLE' | bfcTranslate }}</h1>

    <form [formGroup]="formGroup" (ngSubmit)="save()" class="bfc-margin-top bfc-margin-bottom-3">
        <div class="form-direction">
            <h2>{{ 'OUTAGE_REPORT_PAGE.LOCATION' | bfcTranslate }}</h2>
            <a #myLocation class="bfc-margin-bottom-2" fxFlex="none" fxLayoutAlign="start center"
               (click)="requestGeoLocation()" *ngIf="isGeolocationSupported">
                <bfe-icon name="bfc-interaction-icon-locate" class="bfe-icon-24"></bfe-icon>
                <b>{{ 'OUTAGE_REPORT_PAGE.MY_LOCATION' | bfcTranslate }}</b>
            </a>
            <div class="bfc-form-row-75-25">
                <mat-form-field class="full-width">
                    <mat-label>{{ 'OUTAGE_REPORT_PAGE.STREET' | bfcTranslate }}</mat-label>
                    <input matInput type="text" maxlength="300" required formControlName="street"/>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>{{ 'OUTAGE_REPORT_PAGE.HOUSE_NO' | bfcTranslate }}</mat-label>
                    <input matInput type="text" maxlength="50" required formControlName="houseNo"/>
                </mat-form-field>
            </div>
            <div class="bfc-form-row">
                <mat-form-field class="full-width">
                    <mat-label>{{ 'OUTAGE_REPORT_PAGE.ZIP_AND_CITY' | bfcTranslate }}</mat-label>
                    <input matInput type="text" maxlength="300" required formControlName="zipAndCity"/>
                </mat-form-field>
            </div>
            <div class="bfc-form-row" formGroupName="reasonKnown">
                <mat-checkbox color="primary" class="bfc-checkbox-light" formControlName="reasonKnownActive">
                    {{ 'OUTAGE_REPORT_PAGE.REASON_KNOWN' | bfcTranslate }}
                </mat-checkbox>
                <div id="reasonKnown" class="bfc-margin-top-3" fxLayout="column">
                    <a #uploadButton (click)="imageInput.click()" fxLayout="row" fxLayoutAlign="start center" fxFlex.gt-xs="68" class="bfc-margin-bottom">
                        <mat-icon svgIcon="upload" class="bfc-margin-right"></mat-icon>
                        <b>{{ 'OUTAGE_REPORT_PAGE.UPLOAD_BUTTON' | bfcTranslate }}</b>
                    </a>
                    <input #imageInput type="file" accept="image/*" (change)="handleInputChange($event)" formControlName="file" hidden/>
                    <div fxLayout="row" fxLayoutAlign="start center" class="file-details" *ngIf="file">
                        <bfe-icon name="bfc-interaction-icon-close" (click)="removeFile()" class="remove-icon"></bfe-icon>
                        <b fxFlex>{{fileName}}</b>
                        <b>{{fileSize}}</b>
                    </div>
                    <mat-form-field class="full-width">
                        <mat-label>{{ 'OUTAGE_REPORT_PAGE.MESSAGE' | bfcTranslate }}</mat-label>
                        <textarea matInput #messageControl maxlength="5000" [matTextareaAutosize]="true" formControlName="message">
                        </textarea>
                        <mat-hint align="end">{{messageControl.value.length}} / 5000</mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <div class="bfc-form-row bfc-margin-top-3">
                <h2>{{ 'OUTAGE_REPORT_PAGE.CONTACT_DATA' | bfcTranslate }}</h2>
                <mat-form-field class="full-width">
                    <mat-label>{{ 'OUTAGE_REPORT_PAGE.FIRST_NAME' | bfcTranslate }}</mat-label>
                    <input matInput type="text" maxlength="300" formControlName="firstName"/>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>{{ 'OUTAGE_REPORT_PAGE.LAST_NAME' | bfcTranslate }}</mat-label>
                    <input matInput type="text" maxlength="300" formControlName="lastName"/>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>{{ 'OUTAGE_REPORT_PAGE.PHONE' | bfcTranslate }}</mat-label>
                    <input matInput type="text" maxlength="300" formControlName="phone"/>
                </mat-form-field>
            </div>
            <div class="bfc-form-row bfc-margin-bottom-2 bfc-margin-top-2">
                <mat-checkbox required class="agreement bfc-checkbox-light" color="primary"
                     [ngClass]="{'agreement': true,
                                 'bfc-checkbox-light': true,
                                 'agreement-error': formGroup.get('agreement').hasError('required') && formGroup.get('hidden').touched}"
                    formControlName="agreement">
                    <div [innerHtml]="'OUTAGE_REPORT_PAGE.PRIVACY_AGREEMENT_BKW' | bfcTranslate"></div>
                </mat-checkbox>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start">
                <button mat-button type="submit" color="primary" class="bfc-button-hero" onclick="window.scroll(0,0)"
                        [disabled]="formGroup.invalid || submitting" [loading]="submitting">
                    <span>{{ 'OUTAGE_REPORT_PAGE.SUBMIT_BUTTON' | bfcTranslate }}</span>
                </button>
                <button mat-button type="button" color="primary" onclick="window.scroll(0,0)"
                    (click)="singlePageRouterService.state.next('map')">
                    <span>{{ 'OUTAGE_REPORT_PAGE.BACK_BUTTON' | bfcTranslate }}</span>
                </button>
            </div>
        </div>
    </form>
</bfc-base-page-layout>
