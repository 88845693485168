import { SupplyState } from "./SupplyState";
import { IMarkerClusterer } from "./IMarkerClusterer";
import { MARKER_KEY_SUPPLY_STATE } from "./Const";
import Marker = google.maps.Marker;
import InfoWindow = google.maps.InfoWindow;
import Map = google.maps.Map;

export abstract class SupplyObject {

  public visible: boolean;

  public supplyState: SupplyState;

  public  disconnectionStartTime: string;

  public  disconnectionEndTime: string;

  public supplierName: string;

  public supplierUrl: string;

  public clickCount?: number;
  
  private infoWindow: InfoWindow;

  private marker: Marker;

  private readonly bkwSupplierName: string = "BKW Energie AG";

  constructor(
    public id: number,
    private map: Map,
    private markerClusterer: IMarkerClusterer,
    disconnectionStartTime: string,
    disconnectionEndTime: string
  ) {
    this.disconnectionStartTime = disconnectionStartTime;
    this.disconnectionEndTime = disconnectionEndTime;
  }

  public getMap(): Map {
    return this.map;
  }

  public getInfoWindow(): InfoWindow {
    return this.infoWindow;
  }

  public setInfoWindow(infoWindow: InfoWindow) {
    this.infoWindow = infoWindow;
  }

  public infoWindowIsOpen(): boolean {
    return !!(this.infoWindow as any)?.getMap();
  }

  public getMarker(): Marker {
    return this.marker;
  }

  public setMarker(marker: Marker) {
    this.marker = marker;
  }

  public getMarkerClusterer(): IMarkerClusterer {
    return this.markerClusterer;
  }

  public applySupplyStateAndClicksStyle(hideMarker: boolean): void { // todo move this out of the class
    if (this.hasEnoughClicks()) {
      this.setIcon("assets/img/BKW_Icon_Warning.png");
    } else {
      switch (this.supplyState) {
        case SupplyState.Supplied:
          if (!!hideMarker) {
            this.marker.setVisible(false);
          }

          this.setIcon("assets/img/BKW_Icon_Nooutage.png");
          break;
        case SupplyState.Disconnection:
          this.setIcon("assets/img/BKW_Icon_Maintenance.png");
          break;
        case SupplyState.Failure:
          this.setIcon("assets/img/BKW_Icon_Outage.png");
          this.marker.set(MARKER_KEY_SUPPLY_STATE, SupplyState.Failure);
          break;
        default:
        case null:
        case undefined:
          if (this.supplierName === this.bkwSupplierName) {
            this.setIcon("assets/img/BKW_Icon_Nooutage.png");
          } else {
            this.setIcon("assets/img/BKW_Icon_Outside.png");
          }
          break;
      }
    }
  }

  public abstract getDisplayText(): string;

  public isSuppliedOrOutside(): boolean {
    return this.supplyState === SupplyState.Supplied || !this.supplyState;
  }

  public hasEnoughClicks(): boolean {
    return this.clickCount && this.clickCount > 2;
  }

  private setIcon(url: string): void {
    this.marker.setIcon({
      anchor: new google.maps.Point(19, 51),
      origin: new google.maps.Point(0, 0),
      size: new google.maps.Size(38, 52),
      url: url,
    });
  }

}
