import { IBfcConfiguration } from "@bfl/components/configuration";
import { GENERAL_ENV } from "./environments/general";
import { T_ENV } from "./environments/t";
import { LOCAL_ENV } from "./environments/local";
import { Q_ENV } from "./environments/q";
import { P_ENV } from "./environments/p";
import { ONYX_GENERAL_ENV } from "./environments/onyx/general";
import { ONYX_LOCAL_ENV } from "./environments/onyx/local";
import { ONYX_T_ENV } from "./environments/onyx/t";
import { ONYX_Q_ENV } from "./environments/onyx/q";
import { ONYX_P_ENV } from "./environments/onyx/p";
import { AEK_GENERAL_ENV } from "./environments/aek/general";
import { AEK_LOCAL_ENV } from "./environments/aek/local";
import { AEK_T_ENV } from "./environments/aek/t";
import { AEK_Q_ENV } from "./environments/aek/q";
import { AEK_P_ENV } from "./environments/aek/p";
import { LAGOULE_GENERAL_ENV } from "./environments/lagoule/general";
import { LAGOULE_LOCAL_ENV } from "./environments/lagoule/local";
import { LAGOULE_T_ENV } from "./environments/lagoule/t";
import { LAGOULE_Q_ENV } from "./environments/lagoule/q";
import { LAGOULE_P_ENV } from "./environments/lagoule/p";

export const configurationList: IBfcConfiguration[] = [
  {
    // default = theme: "bkw" and common configurations
    general: GENERAL_ENV,
    local: LOCAL_ENV,
    t: T_ENV,
    q: Q_ENV,
    p: P_ENV,
  },
  {
    theme: "la-goule",
    general: LAGOULE_GENERAL_ENV,
    local: LAGOULE_LOCAL_ENV,
    t: LAGOULE_T_ENV,
    q: LAGOULE_Q_ENV,
    p: LAGOULE_P_ENV,
  },
  {
    theme: "onyx",
    general: ONYX_GENERAL_ENV,
    local: ONYX_LOCAL_ENV,
    t: ONYX_T_ENV,
    q: ONYX_Q_ENV,
    p: ONYX_P_ENV,
  },
  {
    theme: "aek",
    general: AEK_GENERAL_ENV,
    local: AEK_LOCAL_ENV,
    t: AEK_T_ENV,
    q: AEK_Q_ENV,
    p: AEK_P_ENV,
  }, 
];
