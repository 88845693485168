import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  ViewContainerRef,
} from "@angular/core";
import { InfoWindowComponent } from "../components/info-window/info-window.component";
import { DOCUMENT } from "@angular/common";
import { SupplyObject } from "../common/SupplyObject";
import InfoWindow = google.maps.InfoWindow;

@Injectable()
export class InfoWindowService {
  private infoWindows: InfoWindow[] = [];

  private componentRefs: ComponentRef<InfoWindowComponent>[] = [];

  private viewContainerRef: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    @Inject(DOCUMENT) private document,
  ) {
  }

  public createInfoWindow(id: number): InfoWindow {
    let infoWindow: InfoWindow = new google.maps.InfoWindow({
      disableAutoPan: true,
    });
    infoWindow.setContent("<span id='infoWindow_" + id + "'></span>");
    this.infoWindows.push(infoWindow);
    return infoWindow;
  }

  public openInfoWindow(supplyObject: SupplyObject): void {
    // Add InfoWindow to the DOM by opening without position property or Marker
    supplyObject.getInfoWindow().open(supplyObject?.getMap());
    // wait for the element to be in the DOM before creating the component
    setTimeout(() => {
      this.createInfoWindowComponent(supplyObject);
      supplyObject.getInfoWindow().open(null, supplyObject.getMarker());
    }, 100);
  }

  public closeAllInfoWindows(): void {
    this.infoWindows.forEach((infoWindow: InfoWindow) => infoWindow.close());
    this.componentRefs.forEach((componentRef: ComponentRef<InfoWindowComponent>) => componentRef.destroy());
  }

  private createInfoWindowComponent(supplyObject: SupplyObject): ComponentRef<InfoWindowComponent> {
    // 0. Create a component factory
    const factory = this.componentFactoryResolver.resolveComponentFactory(InfoWindowComponent);

    // 1. Create a component reference from the component (calls the Component constructor) and add to the list
    const componentRef = factory.create(this.viewContainerRef.parentInjector);
    componentRef.instance.supplyObject = supplyObject;
    this.componentRefs.push(componentRef);

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.applicationRef.attachView(componentRef.hostView);

    // 3. Get DOM element from component
    const componentElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // 4. Append DOM element to document DOM
    const element = document.getElementById("infoWindow_" + supplyObject.id);
    if (element) {
      element.appendChild(componentElement);
    } else {
      // eslint-disable-next-line no-console
      console.error("InfoWindow with id infoWindow_" + supplyObject.id + " not found in DOM");
    }

    return componentRef;
  }

  public setRootViewContainerRef(viewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }
}

