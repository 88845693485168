export const LOCAL_ENV = {
  // BKW 
  contactUrls: {
    de: "https://connect-t.int.bkw.ch/contact?from=http://localhost:9000",
    fr: "https://connect-t.int.bkw.ch/contact?from=http://localhost:9000",
  },

  // common 
  nvtBackendUrl: "http://localhost:8080/api",
  opSelfServiceApiUrl: "https://api-op-self-service-t.bkw.ch",
};
