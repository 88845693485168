export const DE_TRANSLATIONS = {
  "PAGE_TITLE": "{{companyName}} Services - Stromausfall",
  "PAGE_DESCRIPTION": "Stromausfall in Ihrem Haushalt? Hier finden Sie alle aktuellen Störungen im {{companyName}} Versorgungsgebiet.",
  "ORIGIN_LABEL": "Stromausfall-Info",
  "ORIGIN_LINK": "https://www.bkw.ch/de/privatkunden/kundenservice/",
  "DISCLAIMER": "Elektrische Leitungen und Installationen können immer Strom führen. Jedes Berühren ist lebensgefährlich!",
  "PLEASE_WAIT": "Bitte warten...",
  "UNKNOWN": "unbekannt",
  "MAP_PAGE": {
    "TITLE": "Stromausfall-Info",
    "USE_CURRENT_LOCATION": "Aktuellen Standort verwenden",
    "HINTS_LINK": "Tipps bei Stromausfall",
    "CONTACT_LINK": "Zu den Kontaktinformationen",
    "SHOW_POLYGONS": "Alle Trafostationskreise anzeigen",
    "OUTAGE_REPORT_LINK": "Stromausfall melden",
    "GLOBAL_MESSAGE_LINK": "Globale Nachricht bearbeiten",
    "NO_BLACKOUTS_MESSAGE": "Derzeit sind uns keine Störungen bekannt.",
    "OUTAGE_REPORT": "Stromausfall melden",
    "OR": "oder",
    "OUTAGE_DETAIL_REPORT": "Ursache des Ausfalls melden",
    "INFO_WINDOW": {
      "NO_BLACKOUT": "Derzeit sind uns hier keine Störungen bekannt.",
      "PLANNED_BLACKOUT": "Wir führen zwischen {{disconnectionStartTime}} und {{disconnectionEndTime}} Uhr geplante Instandhaltungsarbeiten durch. Besten Dank für Ihr Verständnis.",
      "UNPLANNED_BLACKOUT": "Wir sind daran, die Störung zu beheben. Danke für Ihr Verständnis.",
      "COUNT_TEXT_1": "TS-Kreis wurde in den letzten 7 Minuten",
      "COUNT_TEXT_2": "{{clickCount}} Mal aufgerufen.",
    },
    "INFO_WINDOW_TITLE": {
      "NO_BLACKOUT": "Keine bekannte Störung",
      "PLANNED_BLACKOUT": "Unterbrüche möglich",
      "UNPLANNED_BLACKOUT": "Stromausfall",
    },
  },
  "LOCATOR": {
    "PLACEHOLDER": "Adresse (Strasse, Nummer und Ort) eingeben",
    "PLACEHOLDER_LAGOULE": "PLZ eingeben",
    "NOT_IN_SUPPLY_AREA": "Dieser Standort ist nicht von {{companyName}} versorgt",
    "SUPPLY_AREA_SUPPLIED_BY": "Dieser Standort wird versorgt von:",
    "NOT_IN_SUPPLY_AREA_TITLE": "Ausserhalb des Versorgungsgebiets",
  },
  "HINTS_PAGE": {
    "TITLE": "Tipps bei Stromausfall",
    "CONTENT": "Für die {{companyName}} ist Versorgungssicherheit das A und O. Um die Kunden lückenlos und zuverlässig mit Strom zu versorgen, wird rund um die Uhr gearbeitet. Trotz allen Bemühungen kann aber in ausserordentlichen Fällen ein Stromausfall geschehen. Darum ist es wichtig, dass man sich einerseits gut auf einen möglichen Ausfall vorbereiten kann und andererseits weiss, wie man zu handeln hat.",
    "CONTENT_AEK_ONYX": "Eine Störung in der elektrischen Versorgung kann auf unterschiedlichste Ereignisse zurück zu führen sein. Damit wir einen Stromunterbruch möglichst rasch beheben können, sind wir auf Ihre wertvolle Mithilfe angewiesen. Bitte teilen Sie uns bei Störungen genau mit, was Sie wo beobach-tet haben.",
    "ACCORDION": {
      "PREPARATION": {
        "TITLE": "Wie kann ich mich vorbereiten?",
        "TEXT_1": "<strong>{{companyName}}-{{numberText}} <a href='tel:{{telNr}}'>{{telNr}}</a></strong>, Telefonnummer Ihres Hauswarts und / oder Elektroinstallateurs bereithalten bzw. im Mobiltelefon speichern",
        "TEXT_2": "Sich mit den Funktionen der Sicherungen und Schaltern in der Wohnung vertraut machen",
        "TEXT_3": "Taschenlampen und Ersatzbatterien bereithalten",
        "TEXT_4": "Computer-Daten regelmässig sichern",
      },
      "ACTIONS": {
        "TITLE": "Was mache ich bei einem Stromausfall?",
        "TEXT_1": "Manuell ausschalten: Herdplatten, Bügeleisen, Elektroheizungen, Bohrmaschinen, Sägen, Staubsauger usw. <strong>Vorsicht:</strong> Eingeschaltet gehen solche Geräte in Betrieb, sobald der Strom wiederfliesst. Dies kann zu gefährlichen Situationen führen!",
        "TEXT_2": "Prüfen, ob Ihr Nachbar auch keinen Strom hat. Falls Stromausfall mehrere Häuser betrifft: {{companyName}}-Störungsnummer <strong><a href='tel:{{telNr}}'>{{telNr}}</a></strong> anrufen",
        "TEXT_3": "Ihre Nachbarn haben Strom:",
        "TEXT_3_1": "1. Sicherungen und Schutzschalter überprüfen,",
        "TEXT_3_2": "2. Hauswart oder Elektroinstallateur kontaktieren",
        "TEXT_4": "Ältere Personen und Kinder über den Stromausfall informieren",
        "TEXT_5": "Tiefkühlgeräte und Kühlschränke nicht öffnen (Kälteverlust vermeiden)",
      },
      "POST_BLACKOUT_ACTIONS": {
        "TITLE": "Was mache ich im Anschluss an einen Stromausfall?",
        "TEXT_1": "Sicherstellen, dass Geräte keine Schäden verursachen können (z. B. eingeschaltete Herdplatten, die sich erhitzen).",
        "TEXT_2": "Kontrollrundgang unternehmen",
        "TEXT_3": "Nach längeren Stromausfällen (> 12 Stunden) Kühl- und Gefriergut kontrollieren und gegebenenfalls entsorgen",
      },
      "HINTS": {
        "TITLE": "Wichtige Hinweise zu Festnetz- und Mobiltelefonen",
        "TEXT_1": "Telefone mit Stromanschluss fallen bei einem Stromausfall sofort aus.",
        "TEXT_2": "Das Mobilfunknetz (inkl. Internet über das Mobiltelefon) sollte noch funktionieren. In seltenen Fällen oder bei sehr grossen Stromunterbrüchen kann es aber sein, dass das Mobiltelefon nicht mehr benutzt werden kann.",
      },
      "NUMBER_TEXT": "Störungsnummer",
      "NUMBER_TEXT_AEK": "Pikettdienst 24h:",
    },
    "DOWNLOADS": {
      "TITLE": "Downloads",
      "HINTS_1": "Stromausfall: Verhaltenstipps für Ihre Sicherheit PDF",
      "HINTS_1_URL": "https://www.bkw.ch/fileadmin/oneportal/outage/Stromausfall_Verhaltenstipps_de.pdf",
      "HINTS_DE": "Stromausfall - Was mache ich bei einem Stromausfall PDF",
      "HINTS_FR": "Coupure de courant - Que dois-je faire en cas de coupure de courant PDF",
      "HINTS_IT": "Interruzione della corrente - Cosa devo fare in caso di interruzione della corrente PDF",
      "HINTS_EN": "Power cut - What should I do in the case of a power cut PDF",
      "HINTS_ES": "Corte de electricidad - Qué debo hacer si se produce un corte de electricidad PDF",
      "HINTS_PT": "Corte de electricidade - O que devo fazer no caso de um corte de electricidade PDF",
      "HINTS_ALB": "Ndërprerjeje te rrymës elektrike - Cfarë duhet të bëj në rast ndërprerjeje te rrymës elektrike PDF",
      "HINTS_BCMS": "Nestane struje - Sta treba da uradim ako nestane struje PDF",
      "HINTS_AMH": "የሀይም መቋረጥ ቢኖር ምን ማድረግ እችላለሁ?",
      "HINTS_TA": "மின் வெட்டு ஏற்பட்டால் நான் என்ன செய்ய வேண்டும்?",
    },
    "BACK_BUTTON": "Zurück zur Übersicht",
    "BACK_LINK": "zurück zur Übersicht",
  },
  "OUTAGE_REPORT_PAGE": {
    "TITLE": "Stromausfall melden",
    "LOCATION": "Ort des Stromausfalls",
    "CONTACT_DATA": "Kontaktdaten (Optional)",
    "LAST_NAME": "Nachname",
    "FIRST_NAME": "Vorname",
    "PHONE": "Telefonnummer",
    "EMAIL": "E-mail",
    "ZIP_AND_CITY": "PLZ und Ort",
    "STREET": "Strasse",
    "HOUSE_NO": "Nr",
    "MY_LOCATION": "Meinen Standort verwenden",
    "MESSAGE": "Ursache der Störung",
    "BACK_BUTTON": "Zurück zur Übersicht",
    "BACK_LINK": "zurück zur Übersicht",
    "REASON_KNOWN": "Ich kenne die Ursache der Störung",
    "UPLOAD_BUTTON": "Bild hochladen (Maximalgrösse: 4MB)",
    "SUBMIT_BUTTON": "Stromausfall melden",
    "MESSAGE_TOO_LONG": "Die angegebene Nachricht ist zu lang.",
    "FILE_TOO_BIG": "Das Bild ist zu gross, bitte wählen Sie ein Bild aus das kleiner als 4MB ist.",
    "SAVE_FAIL": "Der Stromausfall konnte nicht gemeldet werden.",
    "PRIVACY_AGREEMENT_BKW": "Ich stimme hiermit den <a href=\"https://www.bkw.ch/fileadmin/oneportal/mybkw/documents/AGB_Nutzungsbedingungen_Online_Kundencenter_A4_D_BKW_Web.pdf\" target=\"_blank\">Nutzungsbedingungen</a>, <a href=\"https://www.bkw.ch/special-navigations/rechtliches/datenschutzerklaerung\" target=\"_blank\">Datenschutzerklärung</a> und der Verwendung meiner Daten zu.",
  },
  "OUTAGE_REPORT_CONFIRMATION_PAGE": {
    "TITLE": "Vielen Dank",
    "CONFIRMATION": "Ihre Störungsmeldung wird verarbeitet.",
    "BACK_BUTTON": "Zurück zur Übersicht",
    "BACK_LINK": "zurück zur Übersicht",
  },
  "GLOBAL_MESSAGE_PAGE": {
    "TITLE": "Globale Nachricht bearbeiten",
    "FROM": "Von",
    "UNTIL": "Bis",
    "MESSAGE_DE": "Nachricht (deutsch)",
    "MESSAGE_FR": "Nachricht (französisch)",
    "SAVE": "Speichern",
    "DELETE": "Löschen",
    "BACK_BUTTON": "Zurück",
    "BACK_LINK": "zurück zur Übersicht",
    "TIME_RANGE_INVALID": "Der angegebene Zeitraum ist ungültig.",
    "MESSAGE_TOO_LONG": "Die angegebene Nachricht ist zu lang.",
    "SAVING_MESSAGE": "Die globale Nachricht wird gespeichert...",
    "SAVE_SUCCESSFUL": "Die globale Nachricht wurde erfolgreich gespeichert.",
    "SAVE_FAIL": "Die globale Nachricht konnte nicht gespeichert werden.",
    "DELETING_MESSAGE": "Die globale Nachricht wird gelöscht...",
    "DELETE_SUCCESSFUL": "Die globale Nachricht wurde erfolgreich gelöscht.",
    "DELETE_FAIL": "Die globale Nachricht konnte nicht gelöscht werden.",
  },
  "LAGOULE": {
    "HEADER": {
      "CONTACT": "Kontakt",
      "BKW_LINK": "BKW Energie AG",
      "FR": "FR",
      "FR_TITLE": "Französisch",
      "DE": "DE",
      "DE_TITLE": "Deutsch",
    },
  },
};