import { Pipe, PipeTransform } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Pipe({
  name: "nvtReplaceCompanyName",
})
export class NvtReplaceCompanyNamePipe implements PipeTransform {
  constructor(private bfcConfigurationService: BfcConfigurationService) {
  }

  public transform(input: string): string {
    return input.replace(new RegExp("{{companyName}}", "g"), this.bfcConfigurationService.configuration.companyName);
  }
}
