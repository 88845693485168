import { Injectable } from "@angular/core";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { SupplyObject } from "../common/SupplyObject";
import Map = google.maps.Map;

@Injectable()
export class GoogleAnalyticsService {
  constructor(private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService) {
  }

  public logMapType(map: Map): void {
    this.bfcGoogleAnalyticsService.sendGoogleAnalyticsEvent("NVT", "maptype", map.getMapTypeId() as string);
  }

  public logSupplyObjectClicked(supplyObject: SupplyObject): void {
    this.bfcGoogleAnalyticsService.sendGoogleAnalyticsEvent("NVT", "click object", supplyObject.getDisplayText());
  }

  public logSupplyObjectLocated(supplyObject: SupplyObject): void {
    this.bfcGoogleAnalyticsService.sendGoogleAnalyticsEvent("NVT", "locate object", supplyObject.getDisplayText());
  }
}
