export const P_ENV = {
  // BKW
  contactUrls: {
    de: "https://connect.bkw.ch/contact?from=https://outage.bkw.ch",
    fr: "https://connect.bkw.ch/contact?from=https://outage.bkw.ch",
  },

  // common 
  nvtBackendUrl: "https://api-outage.bkw.ch/api",
  opSelfServiceApiUrl: "https://api-op-self-service.bkw.ch",
};