import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { BfcTranslationService } from "@bfl/components/translation";
import { City } from "../../common/City";
import { map, startWith, takeUntil } from "rxjs/operators";
import { MediaChange, MediaObserver } from "@angular/flex-layout";

@Component({
  selector: "app-locator",
  templateUrl: "./locator.component.html",
  styleUrls: ["./locator.component.scss"],
})
export class LocatorComponent implements OnInit, OnChanges, OnDestroy {
  public control = new FormControl();

  public filteredCities: Observable<City[]>;

  private unsubscribe: Subject<void> = new Subject<void>();

  private isMobile: boolean = false;

  @Input()
  public cities: City[] = [];

  @Output()
  public locate: EventEmitter<City> = new EventEmitter();

  @Output()
  public scrollMobile: EventEmitter<void> = new EventEmitter();

  @Input()
  public disabled: boolean;

  @ViewChild("autocomplete")
  public autocomplete: ElementRef;

  constructor(public translateService: BfcTranslationService,
    private mediaObserver: MediaObserver) {
  }

  ngOnInit() {
    this.filteredCities = this.control.valueChanges.pipe(
      startWith(null),
      map(searchText => searchText && typeof (searchText) === "string" ? this.filter(searchText) : null),
    );
    this.mediaObserver.asObservable().pipe(takeUntil(this.unsubscribe)).subscribe((changes: MediaChange[]) => {
      this.isMobile = changes[0].mqAlias === "xs";
    });
  }

  ngOnChanges(): void {
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private filter(searchText: string): City[] {
    let lowerCaseSearchText: string = searchText.toLowerCase();
    let filteredCities: City[] = this.cities.filter(
      city => city instanceof City && city.matchesSearchText(lowerCaseSearchText),
    );
    if (filteredCities.length === 0) {
      filteredCities = [null];
    }
    return filteredCities;
  }

  public displayFn(city: City): string {
    if (city) {
      return city.getDisplayText();
    }
    return null;
  }

  public onCitySelect(city: City): void {

    setTimeout(() => {
      this.autocomplete.nativeElement.blur();
      if (this.isMobile) {
        this.scrollMobile.emit();
      }
    });
    this.locate.emit(city); // this calls map-page component's locateSupplyObject function
  }

}
