import { Component } from "@angular/core";
import { SinglePageRouterService } from "../../services/single-page-router.service";

@Component({
  selector: "app-single-page-router",
  templateUrl: "./single-page-router.component.html",
  styleUrls: ["./single-page-router.component.scss"],
})
export class SinglePageRouterComponent {
  constructor(public singlePageRouterService: SinglePageRouterService) {
  }
}
