import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { SupplyObject } from "../../common/SupplyObject";
import { SupplyState } from "../../common/SupplyState";
import LatLng = google.maps.LatLng;
import { ThemeNameTransformService } from "../theme-name-transform.service";

@Injectable()
export class SupplyZoneRestApiService {
  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    private realmNameTransformService: ThemeNameTransformService) {
  }

  public getSupplyZoneByCity(city: SupplyObject): Observable<ISupplyZoneResponse> {
    return this.httpClient.get<ISupplyZoneResponse>(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/supplyZone/" + city.id);
  }

  public getSupplyZoneByCoordinates(latLng: LatLng): Observable<ISupplyZoneResponse> {
    const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
    return this.httpClient.get<ISupplyZoneResponse>(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/supplyZone/" + latLng.lat() + "-" + latLng.lng(), { params });
  }

  public getSupplyZoneStates(): Observable<ISupplyZoneStateResponse[]> {
    const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
    return this.httpClient.get<ISupplyZoneStateResponse[]>(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/supplyZone/state", { params });
  }

  public getSupplyZoneStatesAndClickCounts(): Observable<ISupplyZoneStateResponse[]> {
    const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
    return this.httpClient.get<ISupplyZoneStateResponse[]>(this.bfcConfigurationService.configuration.nvtBackendUrl
            + "/services/supplyZone/sec/state", { params });
  }

}

export interface ISupplyZoneResponse {
  supplyZoneId: number;
  plz: number;
  city: string;
  area: number[][][][];
}

export interface ISupplyZoneStateResponse {
  supplyZoneId: number;
  plz: number;
  city: string;
  supplyState: SupplyState;
  longitude: number;
  latitude: number;
  failures: number;
  disconnections: number;
  clickCount?: number;
}
