import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime, filter, takeUntil } from "rxjs/operators";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { Subject } from "rxjs";

declare const google;

@Component({
  selector: "app-google-locator",
  templateUrl: "./google-locator.component.html",
  styleUrls: ["./google-locator.component.scss"],
})
export class GoogleLocatorComponent implements OnInit, OnChanges, OnDestroy {
  public control = new FormControl();

  private geocoderResultsFilterPredicate: (geocoderResult: google.maps.GeocoderResult) => boolean = geocoderResult => geocoderResult.address_components.filter(value1 => value1.short_name === "CH").length > 0;

  private unsubscribe: Subject<void> = new Subject<void>();

  private isMobile: boolean = false;

  @ViewChild("locatorInput") locatorInput: ElementRef;

  @Output()
  public locate: EventEmitter<google.maps.LatLng> = new EventEmitter();

  @Output()
  public scrollMobile: EventEmitter<void> = new EventEmitter();

  @Input()
  public disabled: boolean;

  constructor(private mediaObserver: MediaObserver) {
  }

  ngOnInit() {
    this.control.valueChanges.pipe(
      filter(value => value && value.length >= 3),
      debounceTime(300),
      takeUntil(this.unsubscribe),
    ).subscribe(
      value => this.findLocation(value),
    );

    this.mediaObserver.asObservable().pipe(takeUntil(this.unsubscribe)).subscribe((changes: MediaChange[]) => {
      this.isMobile = changes[0].mqAlias === "xs";
    });
  }

  ngOnChanges(): void {
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onEnter(event) {
    event.target.blur();
    if (this.isMobile) {
      this.scrollMobile.emit();
    }
  }

  onBlur(event: FocusEvent) {
    if (event?.relatedTarget) {
      this.locatorInput.nativeElement.focus();
    }
  }

  private findLocation(value: string) {
    const query = this.isZip(value) ? `${value},CH` : value;
    let geocoderRequest: google.maps.GeocoderRequest = {
      address: query,
      region: "CH",
    };
    new google.maps.Geocoder().geocode(geocoderRequest,
      (results, status) => this.processGeocoderResults(results, status),
    );
  }

  private processGeocoderResults(results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) {
    if (status === google.maps.GeocoderStatus.OK || status === google.maps.GeocoderStatus.ZERO_RESULTS) {
      if (results != null) {
        let filteredResults = results.filter(this.geocoderResultsFilterPredicate);
        if (filteredResults.length >= 1) {
          // this calls map-page component's locateLatLng function
          this.locate.emit(
            new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng()),
          );
        }
      }
    } else {
      // eslint-disable-next-line no-console
      console.error("Geocoder returned error status", status);
    }
  }

  private isZip(value: string) {
    const isZip = /^([0-9]{4})$/.test(value);
    return isZip;
  }

}
