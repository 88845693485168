import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { SinglePageRouterService } from "../../services/single-page-router.service";
import { OutageReportService } from "../../services/outage-report.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BfcTranslationService } from "@bfl/components/translation";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { LogService } from "../../services/log.service";
import { LogEvent } from "../../common/LogEvent";
import { NvtReplaceCompanyNamePipe } from "../../../shared/pipes/nvt-replace-company-name.pipe";
import {
  IOutageReportData,
  IOutageReportMultipartFormData,
} from "../../services/rest-api/outage-report-rest-api.service";
import { SupplyObjectService } from "../../services/supply-object.service";
import { SupplyObject } from "../../common/SupplyObject";
import { Trafo } from "../../common/Trafo";
import { City } from "../../common/City";
import {
  BfcContextNotificationPosition,
  BfcNotification,
  BfcNotificationService,
  BfcNotificationType,
} from "@bfl/components/notification";
import { ContextNotificationErrorType } from "./ContextNotificationErrorType";
import { SelfServiceService } from "../../services/self-service.service";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { finalize, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import LatLng = google.maps.LatLng;

@Component({
  selector: "app-outage-report-page",
  templateUrl: "outage-report-page.component.html",
  styleUrls: ["outage-report-page.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OutageReportPageComponent implements AfterViewInit, OnDestroy {
  @HostBinding("class") classes = "outage-report-page";

  @ViewChild("myLocation") myLocationRef: ElementRef;

  @ViewChild("uploadButton") uploadButtonRef: ElementRef;

  private supplyObject: SupplyObject;

  private geoLocation: LatLng;

  public file: Uint8Array;

  public fileName: string;

  public fileSize: string;

  public isGeolocationSupported: boolean = navigator.geolocation !== undefined;

  public formGroup: FormGroup;

  public submitting: boolean = false;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private outageReportService: OutageReportService,
    private supplyObjectService: SupplyObjectService,
    private selfServiceService: SelfServiceService,
    private logService: LogService,
    private nvtReplaceCompanyNamePipe: NvtReplaceCompanyNamePipe,
    private formBuilder: FormBuilder,
    private bfcConfigurationService: BfcConfigurationService,
    private bfcTranslationService: BfcTranslationService,
    private bfcAuthenticationService: BfcAuthenticationService,
    private notificationService: BfcNotificationService,
    public singlePageRouterService: SinglePageRouterService) {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(() => {
      }, () => {
        this.isGeolocationSupported = false;
      });
    }

    this.formGroup = formBuilder.group({
      street: ["", Validators.required],
      houseNo: ["", Validators.required],
      zipAndCity: ["", Validators.required],
      reasonKnown: formBuilder.group({
        reasonKnownActive: [false],
        file: [""],
        message: ["", Validators.maxLength(5000)],
      }),
      firstName: [""],
      lastName: [""],
      phone: [""],
      agreement: [false, Validators.requiredTrue],
      hidden: [""],
    });

    this.outageReportService.supplyObject.pipe(
      takeUntil(this.unsubscribe)).subscribe(value => this.supplyObject = value);

    if (this.bfcAuthenticationService.authenticated) {
      this.selfServiceService.getPerson().pipe(takeUntil(this.unsubscribe)).subscribe(value => {
        this.formGroup.get("firstName").setValue(value.firstName);
        this.formGroup.get("lastName").setValue(value.lastName);
        this.formGroup.get("phone").setValue(value.phone);
        if (value.mobile) {
          this.formGroup.get("phone").setValue(value.mobile);
        }
        const address = value.addressList.pop();
        if (address) {
          this.formGroup.get("street").setValue(address.street);
          this.formGroup.get("houseNo").setValue(address.houseNo);
          this.formGroup.get("zipAndCity").setValue(address.zip + " " + address.city);
        }
      });
    }
  }

  ngAfterViewInit(): void {
    const reasonKnownElement: HTMLElement = document.getElementById("reasonKnown");
    OutageReportPageComponent.hideReasonKnown(reasonKnownElement);

    this.formGroup.get("reasonKnown").get("reasonKnownActive").valueChanges.pipe(
      takeUntil(this.unsubscribe),
    ).subscribe(activated => {

      if (!activated) {
        if (this.formGroup.get("reasonKnown").get("file").value) {
          this.removeFile();
        }

        if (this.formGroup.get("reasonKnown").get("message").value) {
          this.formGroup.get("reasonKnown").get("message").reset("", { emitEvent: false });
        }
        if (!!reasonKnownElement) {
          OutageReportPageComponent.hideReasonKnown(reasonKnownElement);
        }
      } else {
        if (!!reasonKnownElement) {
          reasonKnownElement.style.display = "flex";
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public handleInputChange(event) {
    const mbBase = 1024;

    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file || !file.type.match(pattern)) {
      return;
    }

    if (Math.round(file.size / mbBase) >= 4 * mbBase) {
      this.showContextErrorNotification(ContextNotificationErrorType.Upload);

      if (this.file) {
        this.removeFile();
      }

      return;
    }

    this.fileName = file.name;
    this.fileSize = this.formatBytes(file.size);

    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsArrayBuffer(file);
  }

  public removeFile() {
    this.formGroup.get("reasonKnown").get("file").reset("", { emitEvent: false });
    this.fileName = "";
    this.fileSize = "";
    this.file = undefined;
  }

  public save(): void {
    this.formGroup.get("hidden").markAsTouched();

    if (this.formGroup.get("reasonKnown").get("message").value &&
        this.formGroup.get("reasonKnown").get("message").value.length > 5000) {
      this.notificationService.showNotification({
        type: BfcNotificationType.ERROR,
        message: this.bfcTranslationService.translate("OUTAGE_REPORT_PAGE.MESSAGE_TOO_LONG"),
        options: {
          "duration": 3000,
        },
      });
    }
    if (this.formGroup.valid) {
      this.submitting = true;
      const outageReportData: IOutageReportData = {
        firstName: this.formGroup.get("firstName").value,
        lastName: this.formGroup.get("lastName").value,
        phone: this.formGroup.get("phone").value,
        email: null,
        zipAndCity: this.formGroup.get("zipAndCity").value,
        streetAndHouseNo: this.formGroup.get("street").value + " " + this.formGroup.get("houseNo").value,
        coordinates: null,
        trafoStation: null,
        message: this.formGroup.get("reasonKnown").get("message").value,
        language: this.bfcTranslationService.language,
      };
      if (this.geoLocation) {
        outageReportData.coordinates = this.geoLocation.toString();
      }
      if (this.supplyObject instanceof Trafo) {
        outageReportData.trafoStation = this.supplyObject.id;
      }

      if (!!this.file && !!this.fileName) {
        // send Multiform with Attachment
        const outageReportMultipartFormData: IOutageReportMultipartFormData = {
          outageReport: new Blob([JSON.stringify(outageReportData)], { type: "application/json" }),
          fileName: null,
          file: null,
        };

        const formData = new FormData();

        outageReportMultipartFormData.fileName = this.fileName;
        outageReportMultipartFormData.file = new Blob([this.file], { type: "application/octet-stream" });

        formData.append("fileName", outageReportMultipartFormData.fileName);
        formData.append("file", outageReportMultipartFormData.file, outageReportMultipartFormData.fileName);

        formData.append("outageReport", outageReportMultipartFormData.outageReport, "outageReport");

        this.outageReportService.sendOutageReportMultipartForm(formData).pipe(
          finalize(() => this.submitting = false),
        ).subscribe(
          () => {
            this.logAndConfirm();
          },
          () => {
            this.showError();
          });
      } else {
        // send only outage report
        this.outageReportService.sendOutageReport(outageReportData).pipe(
          finalize(() => this.submitting = false),
        ).subscribe(
          () => {
            this.logAndConfirm();
          },
          () => {
            this.showError();
          });
      }
    }
  }

  requestGeoLocation() {
    navigator.geolocation.getCurrentPosition(position => {
      this.geoLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

      this.supplyObjectService.getSupplyObjectByCoordinates(this.geoLocation).subscribe(
        (supplyObject: SupplyObject) => {
          this.supplyObject = supplyObject;
          if (supplyObject) {
            if (this.supplyObject instanceof City) {
              this.formGroup.get("zipAndCity").setValue(supplyObject.getDisplayText());
            }
          } else {
            this.showContextErrorNotification(ContextNotificationErrorType.Location);
          }
        });
    }, e => {
      // eslint-disable-next-line no-console
      console.error("HTML5 Geolocation retrieval failed", e);
      this.isGeolocationSupported = false;
    });
  }

  public showContextErrorNotification(contextNotificationErrorType: ContextNotificationErrorType) {
    let notification: BfcNotification;

    if (contextNotificationErrorType === ContextNotificationErrorType.Location) {
      notification = {
        type: BfcNotificationType.ERROR,
        message: this.nvtReplaceCompanyNamePipe.transform(
          this.bfcTranslationService.translate("LOCATOR.NOT_IN_SUPPLY_AREA"),
        ),
        options: {
          duration: 3000,
          context: {
            targetElement: this.myLocationRef.nativeElement,
            position: BfcContextNotificationPosition.TOP_LEFT,
          },
        },
      };
    } else if (contextNotificationErrorType === ContextNotificationErrorType.Upload) {
      notification = {
        type: BfcNotificationType.ERROR,
        message: this.bfcTranslationService.translate("OUTAGE_REPORT_PAGE.FILE_TOO_BIG"),
        options: {
          duration: 3000,
          context: {
            targetElement: this.uploadButtonRef.nativeElement,
            position: BfcContextNotificationPosition.TOP_LEFT,
          },
        },
      };
    }

    this.notificationService.showNotification(notification);
  }

  private _handleReaderLoaded(event) {
    const reader = event.target;
    this.file = new Uint8Array(reader.result);
  }

  private formatBytes(bytes: number) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const decimals = 1;
    const sizes = ["Bytes", "KB", "MB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`;
  }

  private static hideReasonKnown(reasonKnownElement: HTMLElement): void {
    reasonKnownElement.style.display = "none";
  }

  private logAndConfirm(): void {
    this.logService.log(LogEvent.StoerungsmeldungEmail, this.supplyObject, this.geoLocation);
    this.singlePageRouterService.state.next("outage-report-confirmation");
  }

  private showError(): void {
    this.notificationService.showNotification({
      type: BfcNotificationType.ERROR,
      message: this.bfcTranslationService.translate("OUTAGE_REPORT_PAGE.SAVE_FAIL"),
      options: {
        "duration": 3000,
      },
    });
  }

}
