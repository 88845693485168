import { Pipe, PipeTransform } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Pipe({
  name: "nvtReplaceTelNr",
})
export class NvtReplaceTelNrPipe implements PipeTransform {
  constructor(private bfcConfigurationService: BfcConfigurationService) {
  }

  public transform(input: string): string {
    return input.replace(new RegExp("{{telNr}}", "g"), this.bfcConfigurationService.configuration.telNr);
  }
}
