import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { moment } from "../common/Moment";
import {
  GlobalMessageRestApiService,
  IGlobalMessageData,
  IPublicGlobalMessageResponse,
} from "./rest-api/global-message-rest-api.service";
import { BfcNotification } from "@bfl/components/notification";

@Injectable()
export class GlobalMessageService {
  public publicGlobalMessage: BehaviorSubject<IPublicGlobalMessageResponse>;

  private globalNotification = new Subject<BfcNotification>();

  constructor(private globalMessageRestApi: GlobalMessageRestApiService) {
    this.publicGlobalMessage = new BehaviorSubject<IPublicGlobalMessageResponse>(null);
    this.loadPublicGlobalMessage().subscribe();
  }

  public get showGlobalMessage(): boolean {
    return this.publicGlobalMessage.getValue() && !!this.publicGlobalMessage.getValue().messageDe;
  }

  public loadPublicGlobalMessage(): Observable<IPublicGlobalMessageResponse> {
    return this.globalMessageRestApi.getPublicGlobalMessage().pipe(
      map((publicGlobalMessageResponse: IPublicGlobalMessageResponse) => {
        this.publicGlobalMessage.next(publicGlobalMessageResponse);
        return publicGlobalMessageResponse;
      }),
    );
  }

  public getGlobalMessage(): Observable<IGlobalMessageData> {
    return this.globalMessageRestApi.getGlobalMessage();
  }

  public setGlobalMessage(validFrom: Date, validUntil: Date, messageDe: string, messageFr: string): Observable<any> {
    let postData: IGlobalMessageData = {
      messageDe: messageDe,
      messageFr: messageFr,
      validFrom: this.formatDate(validFrom),
      validTill: this.formatDate(validUntil),
    };
    return this.globalMessageRestApi.setGlobalMessage(postData);
  }

  public deleteGlobalMessage() {
    return this.globalMessageRestApi.deleteGlobalMessage();
  }

  setNotification(notification: BfcNotification) {
    this.globalNotification.next(notification);
  }

  getNotification(): Observable<any> {
    return this.globalNotification.asObservable();
  }

  private formatDate(date: Date): string {
    return moment(date).format();
  }
}
