import { AfterContentInit, Component, OnDestroy, OnInit } from "@angular/core";
import { SinglePageRouterService } from "../../services/single-page-router.service";
import { MAX_ZOOM, MIN_ZOOM, MIN_ZOOM_MOBILE } from "../../common/Const";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import Map = google.maps.Map;
import { bkwMapStyles } from "../../common/MapStyles";
import * as chJsonData from "../../../../assets/gadm41_CHE_0.json";
import { MultiPolygon } from "../../common/MultiPolygon";
import LatLng = google.maps.LatLng;
import Polygon = google.maps.Polygon;


@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, OnDestroy, AfterContentInit {
  public map: Map;

  private unsubscribe: Subject<void> = new Subject<void>();

  private touchingMap: boolean = false;

  private isPhone: boolean = false;

  private isTablet: boolean = false;

  constructor(private singlePageRouterService: SinglePageRouterService,
    private mediaObserver: MediaObserver) {
    singlePageRouterService.state.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      setTimeout(() => {
        if (this.map) {
          google.maps.event.trigger(this.map, "resize");
        }
      });
    });
  }

  ngOnInit(): void {
    this.mediaObserver.asObservable().pipe(takeUntil(this.unsubscribe)).subscribe((changes: MediaChange[]) => {
      this.isPhone = changes[0].mqAlias === "xs";
      this.isTablet = changes[0].mqAlias === "sm";
    });
  }

  ngAfterContentInit(): void {
    const minZoom = (this.isPhone || this.isTablet) ? MIN_ZOOM_MOBILE : MIN_ZOOM;
    const fullscreenPosition = this.isPhone ?
      google.maps.ControlPosition.BOTTOM_RIGHT : google.maps.ControlPosition.TOP_RIGHT;
    const zoomPosition = this.isPhone ?
      google.maps.ControlPosition.RIGHT_CENTER : google.maps.ControlPosition.RIGHT_BOTTOM;

    const map = document.getElementById("map");

    this.map = new google.maps.Map(map, {
      center: new google.maps.LatLng(46.801111, 7.426667),
      minZoom: minZoom,
      maxZoom: MAX_ZOOM,
      zoom: MIN_ZOOM,
      streetViewControl: false,
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID, google.maps.MapTypeId.TERRAIN],
      },
      fullscreenControlOptions: {
        position: fullscreenPosition,
      },
      zoomControlOptions: {
        position: zoomPosition,
      },
      styles: bkwMapStyles,
    });

    const chMultiPolygon: MultiPolygon = chJsonData as MultiPolygon;
    const polygons: Polygon[] = [];

    chMultiPolygon.coordinates.forEach((coordinate: number[][][]) => {

      coordinate.forEach((areaAndHoles: number[][], index: number) => {

        const latLngs: LatLng[] = [];

        areaAndHoles.forEach((point: number[]) => {
          const latLng: LatLng = new google.maps.LatLng(point[1], point[0]);
          latLngs.push(latLng);
        });

        if (index === 0) {
          const polygon: Polygon = new google.maps.Polygon({
            paths: latLngs,
            fillColor: "#404040",
            fillOpacity: 0,
            strokeColor: "#404040",
            strokeOpacity: 0.5,
            strokeWeight: 3,
            clickable: false,
            map: this.map,
          });
          
          polygons.push(polygon);
        } else {
          const polygon: Polygon = polygons[polygons.length - 1];
          // add the hole
          polygon.getPaths().insertAt(index, new google.maps.MVCArray(latLngs));
        }
      });
    });

    map.addEventListener("touchstart", () => {
      this.touchingMap = true;
    }, false);

    map.addEventListener("touchend", (event) => {
      if (event.touches.length === 0) {
        this.touchingMap = false;
      }
    }, false);

    document.addEventListener("touchmove", (event) => {
      //If the map is currently being touched, block any touch move event except those coming from the map.
      if (this.touchingMap) {
        event.preventDefault();
      }
    }, { passive: false });

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
