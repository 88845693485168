<bfc-single-page-layout>
    <div class="bfc-margin-bottom">
        <a (click)="singlePageRouterService.state.next('map')"><b>« {{ 'OUTAGE_REPORT_CONFIRMATION_PAGE.BACK_LINK' | bfcTranslate }}</b></a>
    </div>
    <h1>{{ 'OUTAGE_REPORT_CONFIRMATION_PAGE.TITLE' | bfcTranslate }}</h1>
    <div class="bfc-form">
        <div class="bfc-form-row">
            {{ 'OUTAGE_REPORT_CONFIRMATION_PAGE.CONFIRMATION' | bfcTranslate }}
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start">
            <button mat-button type="button" color="primary" class="bfc-button-hero" onclick="window.scroll(0,0)"
                (click)="singlePageRouterService.state.next('map')">
                <span>{{ 'OUTAGE_REPORT_CONFIRMATION_PAGE.BACK_BUTTON' | bfcTranslate }}</span>
            </button>
        </div>
    </div>
</bfc-single-page-layout>
