import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { ThemeNameTransformService } from "../theme-name-transform.service";
import { SupplierDto } from "../../common/SupplierDto";
import LatLng = google.maps.LatLng;

@Injectable()
export class SupplierRestApiService {

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    private realmNameTransformService: ThemeNameTransformService) {
  }

  public getSupplier(): Observable<SupplierDto[]> {
    const params: HttpParams = this.realmNameTransformService.transformThemeNameToSupplier();
    return this.httpClient.get<SupplierDto[]>(this.bfcConfigurationService.configuration.nvtBackendUrl
        + "/services/supplier", { params });
  }

  public getSupplierByCoordinates(latLng: LatLng): Observable<SupplierDto[]> {
    return this.httpClient.get<SupplierDto[]>(this.bfcConfigurationService.configuration.nvtBackendUrl
        + "/services/supplier/" + latLng.lng() + "-" + latLng.lat());
  }

}
